import React, { memo, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import TopPanel from 'components/top-panel/TopPanel';
import useToggle from 'hooks/useToggle';
import BossSidebarPanel from 'components/sidebar/BossSidebarPanel';
import useMenu from 'hooks/useMenu';
import BOSS_MENU_PERMISSIONS_LIST from 'shared/bossMenuPermissionsList';

const BossLayout = () => {
  const navigate = useNavigate();
  const { hasChild, permissions } = useMenu();
  const [sideBarToggle, setSideBarToggle] = useToggle('sidebarOpen');

  const view = (code) => hasChild('STATISTICS', code);

  useEffect(() => {
    window.addEventListener('keypress', (event) => {
      if (event && event.keyCode == 91) {
        setSideBarToggle(false);
      }
      if (event && event.keyCode == 93) {
        setSideBarToggle(true);
      }
    });
  }, []);

  useEffect(() => {
    const code = BOSS_MENU_PERMISSIONS_LIST?.find(({ linkData }) =>
      window?.location?.pathname?.includes(linkData?.path)
    )?.code;

    if (view(code)) return;

    navigate(
      `/${
        BOSS_MENU_PERMISSIONS_LIST.find((item) => item.code == permissions[0])
          ?.linkData?.path
      }`
    );
  }, []);

  return (
    <>
      <div className='flex flex-row relative'>
        <div
          className={`aside-container${
            sideBarToggle ? ' sidebar-full' : ' sidebar-small'
          }`}
        >
          <BossSidebarPanel
            sideBarToggle={sideBarToggle}
            setSideBarToggle={setSideBarToggle}
          />
        </div>
        <div
          className={`base-container${
            sideBarToggle ? ' base-container-small' : ' base-container-full'
          }`}
        >
          <div className='header-container'>
            <TopPanel />
          </div>
          <main className='main-container with-max-width'>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default memo(BossLayout);
