import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ContractChildAccordion from './ContractChildAccordion';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

const ContractsAccordion = ({ title = '', itemClassName = '', data = [] }) => {
  const { t } = useTranslation();
  return (
    <Accordion
      sx={{ boxShadow: 'none' }}
      className={`contracts-term-accordion contracts-${itemClassName}`}
    >
      <AccordionSummary
        aria-controls={`contracts-term-item-${itemClassName}-content`}
        id={`contracts-term-item-${itemClassName}-header`}
        expandIcon={<i className='bi bi-chevron-right expand-icon' />}
        className={`contracts-term-accordion-summary ${itemClassName}`}
      >
        <div className='contracts-term-data'>
          <i className='bi bi-calendar4-week text-lg' />
          <div className='contract-range'>
            {title} {t('common.fields.simpleMonth')}
          </div>
          <div className='divider'></div>
          <i className='bi bi-clipboard-check text-lg' />
          <div className='contracts-number'>
            {data.length} {t('boss.contract.contractCount')}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className='contracts-term-accordion-details'>
        <div className='contracts-wrapper'>
          {data && data.length > 0
            ? data.map((item, index) => (
                <ContractChildAccordion
                  item={item}
                  parentClassName={itemClassName}
                  key={`contracts-wrapper-accordion-${itemClassName}-item-${index}`}
                />
              ))
            : ''}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default memo(ContractsAccordion);
