import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { memo } from 'react';
import DateRangeFilter from '../ui/filters/items/DateRangeFilter';
import StatusFilter from '../ui/filters/items/StatusFilter';
import ObjectBlockMultiFilter from '../ui/filters/items/ObjectBlockMultiFilter';
import GenericSelectFilter from '../ui/filters/items/GenericSelectFilter';
import SearchInputNavbar from './items/SearchInputNavbar';
import StaffFilter from '../ui/filters/items/StaffFilter';
import BlocksFilter from '../ui/filters/items/BlocksFilter';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';

const ContractFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper !pt-2 first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end flex justify-between items-center border-b'>
          <div className='text-[16px] leading-[16px] font-semibold'>
            {t('common.button.filter')}
          </div>
          <Button
            color='error'
            // variant='outlined'
            className='!-mr-[5px]'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            {t('common.button.clear')}
          </Button>
        </div>
        <DateRangeFilter
          size='small'
          disabled={isLoading}
          labels={{
            start: t('common.filter.dateFrom'),
            end: t('common.table.till'),
          }}
        />
        <StatusFilter />
        <GenericSelectFilter
          options={[
            { id: 'true', name: t('common.filter.approved') },
            { id: 'false', name: t('common.filter.unapproved') },
          ]}
          query={'formal'}
          label={t('common.filter.formality')}
          size='small'
        />
        <SearchInputNavbar
          inputKey={'number'}
          label={t('payment.add.contract.homeNumber')}
          size='small'
          height='34px'
          className='!my-[5px]'
        />
        <ObjectBlockMultiFilter />
        <BlocksFilter />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='staff'
          className='!min-h-[34px] !h-[34px] !my-[5px !text-[12px]'
        />
        <GenericSelectFilter
          options={currencyTypeSelectOptions}
          optionProp={{ label: 'label', value: 'code' }}
          query={'isvalute'}
          label={t('common.fields.currency')}
          size='small'
        />
        <SearchInputNavbar
          inputKey={'contract_number'}
          label={t('common.fields.contractNumber')}
          size='small'
          height='34px'
          className='!my-[5px]'
        />
        <GenericSelectFilter
          options={[
            { id: 'true', name: t('common.filter.paid') },
            { id: 'false', name: t('common.filter.unpaid') },
          ]}
          query={'paid'}
          label={t('common.filter.contractPaid')}
          size='small'
        />
        <GenericSelectFilter
          options={[
            { id: 'true', name: t('common.filter.discounted') },
            { id: 'false', name: t('common.filter.noDiscount') },
          ]}
          query={'discount'}
          label={t('common.filter.contractDiscount')}
          size='small'
        />
        <GenericSelectFilter
          options={[
            { id: '1', name: t('common.typeList.repairType.repaired') },
            { id: '0', name: t('common.typeList.repairType.noRepaired') },
          ]}
          query={'isrepired'}
          label={t('common.fields.repairType')}
          size='small'
        />
      </div>
    </Drawer>
  );
};
export default memo(ContractFilterDrawer);
