import React, { memo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Checkbox, FormControlLabel } from '@mui/material';
import { t } from 'i18next';

import FormSelectField from 'components/ui/form/FormSelectField';

export default memo(function DiscountPanel({
  setApplyDiscount,
  applyDiscount,
  formik,
  object_id,
}) {
  return (
    <Accordion className='!shadow-none !base-border !rounded-md !w-full'>
      <AccordionSummary
        expandIcon={<i className='bi bi-caret-down-fill' />}
        aria-controls='panel3-content'
        id='panel3-header'
        className='!px-2'
      >
        {t('order.modal.add.additional')}
      </AccordionSummary>
      <AccordionDetails>
        <FormSelectField
          formik={formik}
          label={t('settings.discounts.title')}
          fieldName='discountId'
          path={`admin/discount/objects/${object_id}`}
        />
      </AccordionDetails>
      <AccordionActions className='!justify-start'>
        <FormControlLabel
          control={<Checkbox checked={applyDiscount} />}
          value={applyDiscount}
          checked={applyDiscount}
          disabled={!formik.values.discountId}
          label={t('shaxmatka.moreHomeDetails.applyDiscount')}
          onChange={({ target }) => setApplyDiscount(target?.checked)}
        />
      </AccordionActions>
    </Accordion>
  );
});
