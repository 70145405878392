import { CircularProgress } from '@mui/material';
import BarChart from 'components/ui/charts/BarChart';
import SimpleDateField from 'components/ui/simple-fields/date-picker/SimpleDateField';
import SimpleToggleButtonGroup from 'components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { numericFormatter } from 'react-number-format';
import { useQuery } from 'react-query';
import CURRENCY_TYPE from 'shared/currencyTypeList';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import 'moment/locale/uz-latn';
import 'moment/locale/ru';
import 'moment/locale/uz';
import ObjectBlockMultiFilter from 'components/ui/filters/items/ObjectBlockMultiFilter';
import { useLocation } from 'react-router-dom';

const TabThree = () => {
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { search } = useLocation();
  // const [hasError, setHasError] = useState(false)
  // const [selectedObjectId, setSelectedObjectId] = useState("ALL")
  const [paymentsData, setPaymentsData] = useState([]);
  const [currencyType, setCurrencyType] = useState(CURRENCY_TYPE.SUM.code);
  const [startDate, setStartDate] = useState(
    moment().subtract(11, 'months').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const localeMoment = moment;

  const { isError } = useQuery({
    queryKey: ['payments', search, startDate],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/payment/paymentlist${
          search ? search + '&' : '?'
        }start=${startDate}`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setPaymentsData([...data]);
      }
    },
    // enabled: !hasError,
    // onError: (error) => {
    // 	setHasError(true)
    // },
    retry: false,
  });

  const handleStartDate = (value) => {
    setStartDate(value);
    setEndDate(
      moment(value, 'YYYY-MM-DD').add(11, 'months').format('YYYY-MM-DD')
    );
  };

  const handleEndDate = (value) => {
    setEndDate(value);
    setStartDate(
      moment(value, 'YYYY-MM-DD').subtract(11, 'months').format('YYYY-MM-DD')
    );
  };

  useEffect(() => {
    localeMoment.locale(i18n.language == 'uz' ? 'uz-latn' : 'uz');
  }, [i18n.language]);

  return (
    <div className='boss-payment-tab-wrapper tab-three'>
      <div className='payment-tab-top-actions-wrapper'>
        <div className='payment-tab-top-actions-body'>
          <SimpleToggleButtonGroup
            delay={0}
            duration={0}
            value={currencyType}
            changeFn={(value) => setCurrencyType(value)}
            options={currencyTypeSelectOptions}
            size='small'
          />
          <div className='flex items-center'>
            <div className='max-w-[350px] mr-3 flex items-center'>
              <SimpleDateField
                delay={0}
                duration={0}
                label=''
                name='date'
                openTo='month'
                views={['year', 'month']}
                inputFormat='MMMM, yyyy'
                value={startDate}
                setValue={handleStartDate}
              />
              <span className='mx-1.5'>-</span>
              <SimpleDateField
                delay={0}
                duration={0}
                label=''
                name='date'
                openTo='month'
                views={['year', 'month']}
                inputFormat='MMMM, yyyy'
                value={endDate}
                setValue={handleEndDate}
              />
            </div>
            <div className='max-w-[250px] w-full'>
              <ObjectBlockMultiFilter />
              {/* <ObjectSelectBox
								value={selectedObjectId}
								setValue={setSelectedObjectId}
							/> */}
            </div>
          </div>
        </div>
      </div>
      <div className='payment-tab-chart'>
        {isError ? (
          <div>
            <span className='no-data-found-wrapper'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        ) : paymentsData.length > 0 ? (
          <div className='h-[300px]'>
            <BarChart
              chartLabels={[...paymentsData].map((item) =>
                localeMoment(item.date, 'YYYY-MM-DD').format('MMM. YYYY')
              )}
              chartDatasets={[
                {
                  label: t('boss.payment.title'),
                  data:
                    currencyType == CURRENCY_TYPE.SUM.code
                      ? [...paymentsData].map((item) => item?.totalsum)
                      : [...paymentsData].map((item) => item?.valutelist),
                  borderRadius: 4,
                  borderWidth: 2,
                  borderColor: '#0a6a0a',
                  backgroundColor: '#0a6a0ab0',
                  hoverBackgroundColor: '#0a6a0a',
                  tension: 0.3,
                  categoryPercentage: 0.5,
                },
                {
                  label: t('boss.payment.plan'),
                  data:
                    currencyType == CURRENCY_TYPE.SUM.code
                      ? [...paymentsData].map((item) => item?.sumlist)
                      : [...paymentsData].map((item) => item?.totalvalute),
                  borderRadius: 4,
                  borderWidth: 2,
                  borderColor: '#bfaf07',
                  backgroundColor: '#f2dd0db0',
                  hoverBackgroundColor: '#bfaf07',
                  tension: 0.3,
                  grouped: false,
                },
              ]}
              optionPlugins={{
                legend: {
                  display: true,
                  position: 'top',
                  align: 'center',
                  reverse: true,
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                  },
                  title: {
                    padding: {
                      bottom: 10,
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  yAlign: 'bottom',
                  callbacks: {
                    label: function (tooltipItem) {
                      return ` ${numericFormatter(tooltipItem.raw.toString(), {
                        decimalScale: 3,
                        thousandSeparator: ' ',
                        allowNegative: false,
                        suffix:
                          currencyType == CURRENCY_TYPE.SUM.code
                            ? ' UZS'
                            : ' $',
                      })}`;
                    },
                    labelPointStyle: function () {
                      return {
                        pointStyle: 'rectRounded',
                        rotation: 0,
                      };
                    },
                    title: () => null,
                  },
                },
              }}
              options={{
                onClick: (e, chartElement) => {
                  const index = chartElement[0]?.index;
                  const date = paymentsData[index]?.date;
                  const a = document.createElement('a');
                  const href = `${
                    process.env.REACT_APP_BACKEND_URL
                  }/excel/lists${search ? search + '&' : '?'}${
                    date ? '&date=' + date : ''
                  }`;
                  a.setAttribute('href', href);
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                },
                scales: {
                  y: {
                    ticks: {
                      callback: function (value) {
                        let isDecimalScale =
                          parseFloat(value) /
                            Math.pow(
                              10,
                              currencyType == CURRENCY_TYPE.SUM.code ? 9 : 6
                            ) >=
                          1;
                        return Math.floor(value) > 1
                          ? numericFormatter(
                              isDecimalScale
                                ? (
                                    parseFloat(value) /
                                    Math.pow(
                                      10,
                                      currencyType == CURRENCY_TYPE.SUM.code
                                        ? 9
                                        : 6
                                    )
                                  ).toString()
                                : (
                                    parseFloat(value) /
                                    Math.pow(
                                      10,
                                      currencyType == CURRENCY_TYPE.SUM.code
                                        ? 6
                                        : 0
                                    )
                                  ).toString(),
                              {
                                decimalScale: 3,
                                thousandSeparator: ' ',
                                allowNegative: false,
                                suffix:
                                  currencyType == CURRENCY_TYPE.SUM.code
                                    ? isDecimalScale
                                      ? ' mlrd UZS'
                                      : ' mln UZS'
                                    : isDecimalScale
                                    ? ' mln $'
                                    : ' $',
                              }
                            )
                          : value;
                      },
                    },
                  },
                },
              }}
              plugins={[
                {
                  id: 'increase-legend-spacing',
                  beforeInit(chart) {
                    const originalFit = chart.legend.fit;
                    chart.legend.fit = function fit() {
                      originalFit.bind(chart.legend)();
                      this.height += 20;
                    };
                  },
                },
              ]}
            />
          </div>
        ) : (
          <div className='h-[300px] flex items-center justify-center'>
            <CircularProgress size={30} color='inherit' />
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(TabThree);
