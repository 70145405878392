import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import moment from 'moment';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useInfiniteQuery } from 'react-query';
import CURRENCY_TYPE from 'shared/currencyTypeList';

const ClientContracts = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  // const [hasError, setHasError] = useState(false)
  const [clientsData, setClientsData] = useState([]);
  const [isClientsDataLoading, setIsClientsDataLoading] = useState(true);
  const intObserver = useRef();

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    'clientContracts',
    ({ pageParam = 1 }) => getClientsByPage(pageParam),
    {
      onSuccess: (data) => {
        if (data && data.pages.length > 0) {
          setClientsData([...data.pages.flat()]);
        }
        setIsClientsDataLoading(false);
      },
      // enabled: !hasError,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length ? allPages.length + 1 : undefined;
      },
      // retry: false
    }
  );

  const getClientsByPage = async (pageParam = 1, options = {}) => {
    const response = await axiosPrivate.get(
      `/analytic/contracts/customs?page=${pageParam}`
    );
    return response.data?.data?.data;
  };

  const lastClientRef = useCallback(
    (clientD) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((clients) => {
        if (clients[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (clientD) intObserver.current.observe(clientD);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className='boss-contract-item client-contracts'>
      <div className='boss-contract-item-header'>
        <div className='boss-contract-item-title client-contracts-title'>
          {t('boss.contract.customerContracts')}
        </div>
      </div>

      <div className='client-contracts-body'>
        {isClientsDataLoading ? (
          <div className='flex items-center justify-center bg-white h-full max-h-[200px] rounded-lg'>
            <CircularProgress size={25} color='inherit' />
          </div>
        ) : (
          <div className='clients-contract-wrapper'>
            {clientsData && clientsData.length > 0
              ? clientsData.map((item, index) => (
                  <Accordion
                    sx={{
                      boxShadow: 'none',
                    }}
                    className='client-accordion'
                    key={`client-item-${index}`}
                  >
                    <AccordionSummary
                      aria-controls={`client-item-${index}-content`}
                      id={`client-item-${index}-header`}
                      expandIcon={
                        <i className='bi bi-chevron-right expand-icon' />
                      }
                      className='client-accordion-summary'
                    >
                      <div className='client-data'>
                        <div className='client-data-header'>
                          <div className='client-icon'>
                            <i className='bi bi-person' />
                          </div>

                          <div className='client-data-title'>
                            <div className='client-name'>
                              {item?.surname} {item?.name}
                            </div>
                            <div className='client-phone'>{item?.phone}</div>
                          </div>
                        </div>
                        <div className='client-contracts-number'>
                          {item?.contracts ? item?.contracts.length : 0}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className='client-accordion-details'>
                      <div className='client-contracts-wrapper'>
                        {item?.contracts && item?.contracts.length > 0 ? (
                          item?.contracts.map((contract, contractInd) => (
                            <div
                              className='client-contract-item-wrapper'
                              key={`client-item-${index}-contract-item-${contractInd}`}
                            >
                              <div className='item-details'>
                                <div className='label'>
                                  {t('common.fields.contractNumber')}:
                                </div>
                                <div className='value'>{contract?.name}</div>
                              </div>
                              <div className='item-details'>
                                <div className='label'>
                                  {t('common.fields.contractSum')}:
                                </div>
                                <div className='value'>
                                  <NumericFormat
                                    value={contract?.sum}
                                    displayType={'text'}
                                    allowNegative={false}
                                    thousandSeparator={' '}
                                    decimalScale={0}
                                    className='bg-transparent'
                                    suffix={
                                      contract?.isvalute ==
                                      CURRENCY_TYPE.VALUTE.code
                                        ? ' $'
                                        : ' UZS'
                                    }
                                  />
                                </div>
                              </div>
                              <div className='item-details'>
                                <div className='label'>
                                  {t('common.fields.contractDate')}:
                                </div>
                                <div className='value'>
                                  {moment(contract?.date, 'YYYY-MM-DD').format(
                                    'DD/MM/YYYY'
                                  )}
                                </div>
                              </div>
                              <div className='item-details'>
                                <div className='label'>
                                  {t('common.fields.staff')}:
                                </div>
                                <div className='value'>
                                  {contract?.staff?.name}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='no-data-found-wrapper small-size h-12'>
                            <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                            {t('common.global.noDataFound')}
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              : ''}

            {isFetchingNextPage ? (
              <div className='flex items-center justify-center bg-transparent rounded-lg py-2'>
                <CircularProgress size={20} color='inherit' />
              </div>
            ) : (
              <div
                className='observable-item h-0.5 opacity-0'
                id='observable-client-item'
                ref={lastClientRef}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(ClientContracts);
