const BOSS_MENU_PERMISSIONS_LIST = [
  {
    code: 'MainPage_STAT',
    linkData: {
      path: 'boss/dashboard',
      title: 'sidebar.boss.dashboard',
    },
    iconName: 'bi bi-house',
    order: 1,
  },
  {
    code: 'Payments_STAT',
    linkData: {
      path: 'boss/payment',
      title: 'sidebar.boss.payment',
    },
    iconName: 'bi bi-cash-coin',
    order: 2,
  },
  {
    code: 'DEBITORS_STAT',
    linkData: {
      path: 'boss/arrears',
      title: 'sidebar.boss.arrears',
      beta: true,
    },
    iconName: 'bi bi-gear',
    order: 3,
  },
  {
    code: 'OBJECT_STAT',
    linkData: {
      path: 'boss/residential-complex',
      title: 'sidebar.boss.residentialComplex',
      beta: true,
    },
    iconName: 'bi bi-houses',
    order: 4,
  },
  {
    code: 'CONTRACT_STAT',
    linkData: {
      path: 'boss/contract',
      title: 'sidebar.boss.contract',
      beta: true,
    },
    iconName: 'bi bi-clipboard-check',
    order: 5,
  },
  {
    code: 'CRM_STAT',
    linkData: {
      path: 'boss/crm',
      title: 'sidebar.boss.crm',
      beta: true,
    },
    iconName: 'bi bi-grid-1x2',
    order: 6,
  },
  {
    code: 'SALE_STAT',
    linkData: {
      path: 'boss/sale',
      title: 'sidebar.boss.sale',
      beta: true,
    },
    iconName: 'bi bi-graph-up-arrow',
    order: 7,
  },
];

export default BOSS_MENU_PERMISSIONS_LIST;
