import { CircularProgress, Divider } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Order({
  queries,
  dataList,
  fieldFilteredList,
  formik,
  getFields,
  checkedList,
}) {
  const { t } = useTranslation();
  const { setFieldValue, values } = formik;

  const onChangeFields = useCallback((e) => {
    const { value, name } = e?.target;
    formik.setFieldValue(name, JSON.parse(value));
  }, []);

  const onChangeUser = useCallback((e, f1) => {
    const { value, name } = e?.target;
    const item = JSON.parse(value);
    formik.setFieldValue(name, item?.id);
    formik.setFieldValue(f1, item);
  }, []);

  const onChangeCrm = useCallback((e, blockname, f1, f2) => {
    const { value, name } = e?.target;
    const item = JSON.parse(value);
    formik.setFieldValue(name, item?.id);
    formik.setFieldValue(f1, item);
    formik.setFieldValue('blockname', blockname);
    formik.setFieldValue(f2, item?.crm_block_id);
  }, []);

  const onChangeContact = useCallback(
    (e) => {
      const { value, name } = e?.target;
      const item = JSON.parse(value);

      if (values?.contactIdList?.includes?.(item?.id)) {
        const newContacts = values?.contacts?.filter(
          (contact) => contact?.id != item?.id
        );
        const newContactIdList = newContacts?.map((contact) => contact?.id);
        setFieldValue(name, newContacts);
        setFieldValue('contactIdList', newContactIdList);
      } else {
        const newContacts = [...values?.contacts, item];
        const newContactIdList = [...values?.contactIdList, item?.id];
        setFieldValue(name, newContacts);
        setFieldValue('contactIdList', newContactIdList);
      }
    },
    [values?.contactIdList, values?.contacts]
  );

  const onChangeLeads = useCallback((e) => {
    const { value, name } = e?.target;
    formik.setFieldValue(name, value);
  }, []);

  const getFieldValue = useCallback(
    (index, id) => {
      if (
        getFields?.every((f) => !(f?.isFetching || f?.isLoading || !f?.data))
      ) {
        const field = getFields?.[index]?.data?.find(
          ({ field_id }) => field_id == id
        );
        return field?.value || '';
      } else {
        return '';
      }
    },
    [getFields]
  );

  const bigDataList = useMemo(() => {
    const leadData = dataList?.map((data, i) => ({
      ...data,
      budget: data?.budget || '0',
      fields: fieldFilteredList?.map((field) => ({
        ...field,
        value: getFieldValue(i, field?.id),
      })),
    }));
    return leadData;
  }, [dataList, fieldFilteredList]);

  useEffect(() => {
    const contactList = bigDataList
      ?.map(({ contacts }) => contacts)
      ?.filter((contacts) => contacts)
      ?.flat(Infinity);
    const ids = contactList?.map(({ id }) => id);
    const mainLead = bigDataList?.[0];
    const name = mainLead?.name
      ? mainLead?.name
      : bigDataList?.find((d) => d?.name)?.name;

    const user = mainLead?.user
      ? mainLead?.user
      : bigDataList?.find((d) => d?.user)?.user;

    const user_id = mainLead?.user_id
      ? mainLead?.user_id
      : bigDataList?.find((d) => d?.user_id)?.user_id;

    const created_at = mainLead?.created_at
      ? mainLead?.created_at
      : bigDataList?.find((d) => d?.created_at)?.created_at;

    const budget = mainLead?.budget
      ? mainLead?.budget
      : bigDataList?.find((d) => d?.budget)?.budget;

    const crm_block_id = mainLead?.crm_block_id
      ? mainLead?.crm_block_id
      : bigDataList?.find((d) => d?.crm_block_id)?.crm_block_id;

    const crm_block_item_id = mainLead?.crm_block_item_id
      ? mainLead?.crm_block_item_id
      : bigDataList?.find((d) => d?.crm_block_item_id)?.crm_block_item_id;

    const crm_block_item = mainLead?.crm_block_item
      ? mainLead?.crm_block_item
      : bigDataList?.find((d) => d?.crm_block_item)?.crm_block_item;

    const blockname = mainLead?.blockname
      ? mainLead?.blockname
      : bigDataList?.find((d) => d?.blockname)?.blockname;

    const fields = mainLead?.fields
      ? mainLead?.fields?.map((field) => ({ ...field, field_id: field?.id }))
      : bigDataList
          ?.find((d) => d?.fields)
          ?.fields?.map((field) => ({ ...field, field_id: field?.id }));

    setFieldValue('leads', checkedList);
    setFieldValue('created_at', created_at);
    setFieldValue('blockname', blockname);
    setFieldValue('fields', fields);
    setFieldValue('budget', budget);
    setFieldValue('crm_block_id', crm_block_id);
    setFieldValue('crm_block_item_id', crm_block_item_id);
    setFieldValue('crm_block_item', crm_block_item);
    setFieldValue('contactIdList', ids);
    setFieldValue('user', user);
    setFieldValue('user_id', user_id);
    setFieldValue('contacts', contactList);
    setFieldValue('name', name);
  }, [JSON.stringify(bigDataList), JSON.stringify(dataList)]);

  return (
    <div className='h-[calc(100vh-400px)] min-h-[500px] grid grid-cols-[1fr,300px]'>
      <div className='leads-list overflow-x-auto my-scroll'>
        <div className='flex whitespace-nowrap border rounded-lg p-3 mt-4'>
          {queries?.some(
            ({ isLoading, isFetching }) => isLoading || isFetching
          ) || bigDataList?.length === 0 ? (
            <div className='circular-progress-box py-5'>
              <CircularProgress size={35} />
            </div>
          ) : (
            bigDataList?.map((lead, i) => (
              <div className='grow whitespace-nowrap min-w-fit text-sm' key={i}>
                <div>
                  <span className='text-blue-600'>
                    {t('common.button.selectAll')}
                  </span>
                  &nbsp;
                  <Link
                    className='no-underline text-gray-500'
                    to={`/admin/crm/lead/edit/${lead?.id}`}
                  >
                    {t('common.button.toLead')}
                  </Link>
                </div>
                <div className='my-1 py-[5px]'>
                  <label className='flex items-center gap-2'>
                    <input
                      type='radio'
                      name='name'
                      onChange={onChangeLeads}
                      checked={values?.name == lead?.name}
                      value={lead?.name}
                    />
                    {lead?.name}
                  </label>
                </div>
                <Divider className='!my-1' />
                <div className='my-1 py-[5px]'>
                  <label className='flex items-center gap-2'>
                    <input
                      type='radio'
                      name='user_id'
                      checked={values?.user_id == lead?.user_id}
                      onChange={(e) => onChangeUser(e, 'user')}
                      value={JSON.stringify(lead?.user)}
                    />
                    {lead?.user?.name}
                  </label>
                </div>
                <Divider className='!my-1' />
                <div className='my-1 py-[5px]'>
                  <label className='flex items-center gap-2'>
                    <input
                      type='radio'
                      name='created_at'
                      checked={values?.created_at == lead?.created_at}
                      onChange={onChangeLeads}
                      value={lead?.created_at}
                    />
                    {moment(lead?.created_at).format('DD.MM.YYYY HH:mm:ss')}
                  </label>
                </div>
                <Divider className='!my-1' />
                <div className='my-1 py-[5px]'>
                  <label className='flex items-center gap-2'>
                    <input
                      type='radio'
                      name='budget'
                      checked={values?.budget == lead?.budget}
                      onChange={onChangeLeads}
                      value={lead?.budget}
                    />
                    {lead?.budget}
                  </label>
                </div>
                <Divider className='!my-1' />
                <div className='my-1 py-[5px]'>
                  <label className='flex items-center gap-2'>
                    <input
                      type='radio'
                      name='crm_block_item_id'
                      checked={
                        values?.crm_block_item_id == lead?.crm_block_item_id
                      }
                      onChange={(e) =>
                        onChangeCrm(
                          e,
                          lead?.blockname,
                          'crm_block_item',
                          'crm_block_id'
                        )
                      }
                      value={JSON.stringify(lead?.crm_block_item)}
                    />
                    <span className='inline-block px-2 p-1 border rounded-md'>
                      {lead?.blockname || ''}
                      <span
                        className='inline-block text-white ml-1 px-1 rounded-md'
                        style={{
                          background: lead?.crm_block_item?.color,
                        }}
                      >
                        {lead?.crm_block_item?.name}
                      </span>
                    </span>
                  </label>
                </div>
                <Divider className='!my-1' />
                {lead?.fields?.map((field, i) => {
                  return (
                    <React.Fragment key={field?.id}>
                      <div className='my-1 py-[5px]'>
                        {field?.value ? (
                          <label className='flex items-center gap-2'>
                            <input
                              type='radio'
                              name={`fields.[${i}]`}
                              onChange={onChangeFields}
                              checked={
                                values?.fields?.find(
                                  (f) => f?.field_id == field?.id
                                )?.value == field?.value
                              }
                              value={JSON.stringify({
                                value: field?.value,
                                name: field?.name,
                                field_id: field?.id,
                              })}
                            />
                            {field?.value || ''}
                          </label>
                        ) : (
                          <div className='p-[10px]'></div>
                        )}
                      </div>
                      <Divider className='!my-1' />
                    </React.Fragment>
                  );
                })}
                {lead?.contacts?.map((contact) => (
                  <div className='my-1' key={contact?.id}>
                    <label className='my-2'>
                      <input
                        type='checkbox'
                        name='contacts'
                        checked={formik.values?.contactIdList?.some(
                          (contactId) => contactId == contact?.id
                        )}
                        value={JSON.stringify(contact)}
                        onChange={onChangeContact}
                      />
                      <span className='inline-block px-2 mt-0.5'>
                        {contact?.name || ''}
                      </span>
                      {contact?.phones?.map((phone) => (
                        <span className='block ml-4'>{phone?.phone}</span>
                      ))}
                    </label>
                  </div>
                ))}
                <Divider className='!my-1' />
              </div>
            ))
          )}
        </div>
      </div>
      <div className='result-combine flex flex-col p-2 py-4 pt-12'>
        <div className='text-sm leading-[14.7px] my-[5px]'>
          <div className='title text-gray-500'>
            {t('common.fields.simpleName')}
          </div>
          <div className='value'>{values?.name || ''}</div>
        </div>
        <div className='text-sm leading-[14.7px] my-[5px]'>
          <div className='title text-gray-500'>{t('common.fields.staff')}</div>
          <div className='value'>{values?.user?.name || ''}</div>
        </div>
        <div className='text-sm leading-[14.7px] my-[5px]'>
          <div className='title text-gray-500'>{'common.fields.createdAt'}</div>
          <div className='value'>
            {moment(values?.created_at).format('DD.MM.YYYY HH:mm:ss') || ''}
          </div>
        </div>
        <div className='text-sm leading-[14.7px] my-[5px]'>
          <div className='title text-gray-500'>{t('common.fields.budget')}</div>
          <div className='value'>{values?.budget || ''}</div>
        </div>
        <div className='text-sm leading-[14.7px] my-[5px]'>
          <div className='title text-gray-500'>
            {t('common.filter.crm_block_id')}
          </div>
          <div className='value'>
            <span className='inline-block px-2 p-1 border rounded-md'>
              {values?.blockname || ''}
              <span
                className='inline-block text-white ml-1 px-1 rounded-md'
                style={{
                  background: values?.crm_block_item?.color,
                }}
              >
                {values?.crm_block_item?.name}
              </span>
            </span>
          </div>
        </div>
        {values?.fields?.map((field, i) => {
          return (
            <React.Fragment key={field?.id}>
              <div className='text-sm leading-[14.7px] my-[5px]'>
                <div className='title text-gray-500'>{field?.name}</div>
                <div className='value'>{field?.value || ''}</div>
              </div>
            </React.Fragment>
          );
        })}
        {values?.contacts?.map((contact, i) => {
          return (
            <React.Fragment key={contact?.id}>
              <div className='text-sm leading-[15px] my-[5px]'>
                <div className='title text-gray-700'>{contact?.name}</div>

                {contact?.phones?.map((phone) => (
                  <span className='block'>{phone?.phone}</span>
                ))}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
