import { Button, CircularProgress } from '@mui/material';
// import ObjectSelectBox from 'components/boss/ObjectSelectBox';
import LineChart from 'components/ui/charts/LineChart';
import SimpleToggleButtonGroup from 'components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useSearch from 'hooks/useSearch';
import useSetQuery from 'hooks/useSetQuery';
// import useTopPanel from 'hooks/useTopPanel';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import colorGenerator from 'utils/colorGenerator';
import { Tooltip } from 'chart.js';
import OrderStatistics from './OrderStatistics';
import StaffFilter from 'components/ui/filters/items/StaffFilter';
import CrmBlockFilter from 'components/ui/filters/items/CrmBlockFilter';
import DateTypeSelectFilter from 'components/dashboard/items/dateTypeSelectFilter';
import { useLocation } from 'react-router-dom';

const localeMoment = moment;
Tooltip.positioners.cursor = function (chartElements, coordinates) {
  return coordinates;
};

const Leads = () => {
  const { t, i18n } = useTranslation();
  const dateTypes = [
    {
      code: 'DAILY',
      label: 'boss.payment.daily',
      parserCode: 'months',
      isFourYears: false,
    },
    {
      code: 'MONTH',
      label: 'boss.payment.monthly',
      parserCode: 'years',
      isFourYears: false,
    },
    {
      code: 'YEAR',
      label: 'boss.payment.yearly',
      parserCode: 'years',
      isFourYears: true,
    },
  ];
  // const { setComponent } = useTopPanel();
  const axiosPrivate = useAxiosPrivate();
  const query = useSearch();
  const setQuery = useSetQuery();
  const { search } = useLocation();
  const [bgColors, setBgColors] = useState({});
  const [salesData, setSalesData] = useState([]);
  const [salesByRepairTypeData, setSalesByRepairTypeData] = useState([]);
  const [pipes, setPipes] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [salesDateList, setSalesDateList] = useState(
    Array.from({ length: localeMoment().daysInMonth() }, (x, i) =>
      localeMoment().startOf('month').add(i, 'days').format('DD')
    )
  );
  // const [selectedObjectId, setSelectedObjectId] = useState('ALL');
  const [dateType, setDateType] = useState('DAILY');
  const [startAndFinishDates, setStartAndFinishDates] = useState({
    startDate: moment().startOf('months').format('YYYY-MM-DD'),
    finishDate: moment().endOf('months').format('YYYY-MM-DD'),
  });

  const { isLoading, isFetching, isError } = useQuery({
    queryKey: ['analyticSales', startAndFinishDates, search],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/crm/leads${
          search ? search + '&' : '?'
        }type=${dateType}&start=${
          query.get('from') || startAndFinishDates.startDate
        }&finish=${query.get('till') || startAndFinishDates.finishDate}`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data?.pipes && data?.pipes.length > 0) {
        setPipes(data?.pipes);
      }
      if (data?.staffes && data?.staffes.length > 0) {
        setStaffs(data?.staffes);
      }
      if (data?.data && data?.data.length > 0) {
        setSalesData(data?.data);
        setSalesByRepairTypeData(getRepairTypesFromData(data?.data));
      }
    },
    // enabled: !hasError,
    // onError: (error) => {
    // 	setHasError(true)
    // },
    retry: false,
  });

  const getRepairTypesFromData = (data) => {
    let newBgColors = { ...bgColors };
    if (!newBgColors.hasOwnProperty('types')) {
      newBgColors = {
        ...bgColors,
        types: Array.from({ length: 3 }, () => colorGenerator()),
      };
    }
    setBgColors(newBgColors);
    const leadCount = [];
    const successLeads = [];
    const unsuccessLead = [];
    data.forEach((item) => {
      leadCount.push(item?.count);
      successLeads.push(item?.['success']);
      unsuccessLead.push(item?.['unsuccess']);
    });
    return [
      {
        label: t('boss.crm.lead.leads'),
        value: leadCount,
        bgColor: newBgColors.types[0],
      },
      {
        label: t('boss.crm.lead.success'),
        value: successLeads,
        bgColor: newBgColors.types[1],
      },
      {
        label: t('boss.crm.lead.failed'),
        value: unsuccessLead,
        bgColor: newBgColors.types[2],
      },
    ];
  };

  const handleDateType = (value) => {
    setQuery('from');
    setQuery('till');
    setQuery('type-date-picker');
    setDateType(value);
    let currDateType = dateTypes.find((item) => item.code == value);

    if (currDateType.isFourYears) {
      let newStartDate = moment()
        .subtract(4, currDateType.parserCode)
        .startOf(currDateType.parserCode)
        .format('YYYY-MM-DD');
      setStartAndFinishDates({
        startDate: newStartDate,
        finishDate: moment()
          .endOf(currDateType.parserCode)
          .format('YYYY-MM-DD'),
      });
      updateSalesDateList(currDateType, newStartDate);
    } else {
      let newStartDate = moment()
        .startOf(currDateType.parserCode)
        .format('YYYY-MM-DD');
      setStartAndFinishDates({
        startDate: newStartDate,
        finishDate: moment()
          .endOf(currDateType.parserCode)
          .format('YYYY-MM-DD'),
      });
      updateSalesDateList(currDateType, newStartDate);
    }
  };

  const handlePrevMonth = () => {
    let currDateType = dateTypes.find((item) => item.code == dateType);

    let subtractNumber = currDateType.isFourYears ? 4 : 1;

    let newStartDate = moment(startAndFinishDates.startDate, 'YYYY-MM-DD')
      .subtract(subtractNumber, currDateType.parserCode)
      .startOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    let newFinishDate = moment(startAndFinishDates.finishDate, 'YYYY-MM-DD')
      .subtract(subtractNumber, currDateType.parserCode)
      .endOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    setStartAndFinishDates({
      startDate: newStartDate,
      finishDate: newFinishDate,
    });

    updateSalesDateList(currDateType, newStartDate);
  };

  const handleNextMonth = () => {
    let currDateType = dateTypes.find((item) => item.code == dateType);

    let addNumber = currDateType.isFourYears ? 4 : 1;

    let newStartDate = moment(startAndFinishDates.startDate, 'YYYY-MM-DD')
      .add(addNumber, currDateType.parserCode)
      .startOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    let newFinishDate = moment(startAndFinishDates.finishDate, 'YYYY-MM-DD')
      .add(addNumber, currDateType.parserCode)
      .endOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    setStartAndFinishDates({
      startDate: newStartDate,
      finishDate: newFinishDate,
    });

    updateSalesDateList(currDateType, newStartDate);
  };

  const updateSalesDateList = (currDateType, newStartDate) => {
    let newSalesDateList =
      currDateType.parserCode == 'months'
        ? Array.from(
            { length: localeMoment(newStartDate, 'YYYY-MM-DD').daysInMonth() },
            (x, i) =>
              localeMoment(newStartDate, 'YYYY-MM-DD')
                .startOf('months')
                .add(i, 'days')
                .format('DD')
          )
        : currDateType.parserCode == 'years' && !currDateType.isFourYears
        ? Array.from({ length: 12 }, (x, i) =>
            localeMoment(newStartDate, 'YYYY-MM-DD')
              .startOf('years')
              .add(i, 'months')
              .format('MMMM')
          )
        : Array.from({ length: 5 }, (x, i) =>
            localeMoment(newStartDate, 'YYYY-MM-DD')
              .startOf('years')
              .add(i, 'years')
              .format('YYYY')
          );
    setSalesDateList(newSalesDateList);
  };

  const getDataSetWithOptions = (item) => {
    return {
      data: item.value,
      label: t(item.label),
      borderRadius: 8,
      borderWidth: 2,
      borderColor: item.bgColor,
      backgroundColor: item.bgColor,
      xAxisID: 'xAxis',
      yAxisID: 'yAxis',
      pointStyle: 'circle',
      pointRadius: 5,
      pointHoverRadius: 7,
      // pointBorderColor: "transparent",
      // pointHoverBorderColor: "transparent",
      // pointBorderWidth: 0,
      tension: 0.3,
    };
  };

  useEffect(() => {
    // setComponent(<div className='component-title'>{t('boss.sale.title')}</div>);
    localeMoment.locale(i18n.language == 'uz' ? 'uz-latn' : 'uz');
  }, [i18n.language]);

  return (
    <div className='component-wrapper boss-sale-wrapper'>
      <div className='sale-actions-wrapper'>
        <div className='sale-actions-body'>
          <div className='flex items-center'>
            <div className='min-w-[250px]'>
              <SimpleToggleButtonGroup
                delay={0}
                duration={0}
                value={dateType}
                changeFn={handleDateType}
                options={dateTypes}
                size='small'
                fullWidth={true}
              />
            </div>
            <div className='min-w-[180px] ml-4'>
              <StaffFilter />
            </div>
            <div className='min-w-[180px] ml-4'>
              <DateTypeSelectFilter />
            </div>
            <div className='min-w-[180px] ml-4'>
              <CrmBlockFilter />
            </div>
            {/* <div className='w-full ml-4'>
              <ObjectSelectBox
                value={selectedObjectId}
                setValue={setSelectedObjectId}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className='sale-chart'>
        <div className='chart-header'>
          <Button
            variant='action'
            color='secondary'
            onClick={() => handlePrevMonth()}
            disabled={isLoading || isFetching}
          >
            <i className='bi bi-chevron-left' />
          </Button>
          <div className='chart-title'>
            {dateType == 'DAILY' &&
              `${localeMoment(
                startAndFinishDates.startDate,
                'YYYY-MM-DD'
              ).format('MMMM')}, `}
            {dateType != 'YEAR' &&
              localeMoment(startAndFinishDates.startDate, 'YYYY-MM-DD').year()}
            {dateType == 'YEAR' &&
              `${localeMoment(
                startAndFinishDates.startDate,
                'YYYY-MM-DD'
              ).year()} - ${localeMoment(
                startAndFinishDates.finishDate,
                'YYYY-MM-DD'
              ).year()}`}
          </div>
          <Button
            variant='action'
            color='secondary'
            onClick={() => handleNextMonth()}
            disabled={isLoading || isFetching}
          >
            <i className='bi bi-chevron-right' />
          </Button>
        </div>
        {isError ? (
          <div>
            <span className='no-data-found-wrapper'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        ) : salesData.length > 0 ? (
          <div className='h-[300px]'>
            <LineChart
              chartLabels={salesDateList}
              chartDatasets={salesByRepairTypeData.map((item) =>
                getDataSetWithOptions(item)
              )}
              optionPlugins={{
                legend: {
                  display: true,
                  position: 'right',
                  align: 'center',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  yAlign: 'bottom',
                  callbacks: {
                    labelPointStyle: function () {
                      return {
                        pointStyle: 'rectRounded',
                        rotation: 0,
                      };
                    },
                    title: () => null,
                  },
                  position: 'cursor',
                },
              }}
              options={{
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
              }}
              plugins={[
                {
                  id: 'crosshairLabel',
                  afterDatasetsDraw(chart, args, plugins) {
                    const { ctx } = chart;
                    ctx.strokeStyle = 'gray';
                    if (chart.crosshair) {
                      ctx.save();
                      ctx.beginPath();
                      chart.crosshair.forEach((line) => {
                        ctx.setLineDash([5, 5]);
                        ctx.moveTo(line.startX, line.startY);
                        ctx.lineTo(line.endX, line.endY);
                        ctx.stroke();
                      });
                    }
                    ctx.setLineDash([]);
                  },
                  afterEvent(chart, args) {
                    const {
                      chartArea: { left, right, top, bottom },
                    } = chart;
                    const xCoor = args.event.x;
                    const yCoor = args.event.y;
                    if (!args.inChartArea && chart.crosshair) {
                      chart.crosshair = undefined;
                      args.changed = true;
                    } else if (args.inChartArea) {
                      /* chart.crosshair = [
												{
													startX: left,
													startY: yCoor,
													endX: xCoor,
													endY: yCoor
												},
												{
													startX: xCoor,
													startY: yCoor,
													endX: xCoor,
													endY: bottom
												}
											] */
                      chart.crosshair = [
                        {
                          startX: left,
                          startY: yCoor,
                          endX: right,
                          endY: yCoor,
                        },
                        {
                          startX: xCoor,
                          startY: top,
                          endX: xCoor,
                          endY: bottom,
                        },
                      ];
                      args.changed = true;
                    }
                  },
                },
              ]}
            />
          </div>
        ) : (
          <div className='h-[300px] flex items-center justify-center'>
            <CircularProgress size={30} color='inherit' />
          </div>
        )}
      </div>

      <div className='sale-chart'>
        <OrderStatistics
          pipes={pipes}
          staffs={staffs}
          isLoading={isLoading || isFetching}
        />
      </div>
    </div>
  );
};
export default memo(Leads);
