import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PaymentHistoryTable from 'components/ui/tables/PaymentHistoryTable';
import ContractPaymentSimpleListModal from './ContractPaymentSimpleListModal';

export default function ContractInfoTables({
  id,
  contractQuery,
  paymentsListQuery,
  refetch,
  setRefetch,
}) {
  const { t } = useTranslation();
  const [totalPayment, setTotalPayment] = React.useState(0);
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <div className='bg-white p-5 rounded-[15px]'>
      <Box
        sx={{
          background: '#76768014',
          padding: '5px',
          borderRadius: '15px',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          variant='fullWidth'
          type='callsHistory'
          className='custom-tab-fulfilled'
        >
          <Tab
            label={
              <div className='relative w-full'>
                <span className='absolute top-1/2 -translate-y-1/2 left-2 w-6 h-6 rounded-full bg-red-500 text-white flex items-center justify-center'>
                  {totalPayment || 0}
                </span>
                {t('contract.view.paymentHistory')}
              </div>
            }
            value={0}
          />
          <Tab
            label={
              <div className='relative w-full'>
                <span className='absolute top-1/2 -translate-y-1/2 left-2 w-6 h-6 rounded-full bg-red-500 text-white flex items-center justify-center'>
                  {paymentsListQuery?.data?.length || 0}
                </span>
                {t('contract.view.paymentTable')}
              </div>
            }
            value={1}
          />
        </Tabs>
      </Box>
      {/* {tabIndex == 0 && ( */}
      <div className={`mt-2 ${tabIndex != 0 ? 'hidden' : ''}`}>
        <PaymentHistoryTable
          dataPath={`dictionary/paymentscontract/${id}`}
          setTotal={setTotalPayment}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      </div>
      {/* )} */}
      {tabIndex == 1 && (
        <ContractPaymentSimpleListModal
          data={contractQuery?.data}
          paymentsListQuery={paymentsListQuery}
        />
      )}
    </div>
  );
}
