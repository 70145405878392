import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import ImagePreviewDialog from 'components/ui/dialogs/ImagePreviewDialog';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BlockAddEditImageModal from './BlockAddEditImageModal';

const BlockImageModal = (props) => {
  const { open, setOpen, data } = props;
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [currLayoutImageLink, setCurrLayoutImageLink] = useState('');
  const [currLayoutData, setCurrLayoutData] = useState({});
  const [openBlockAddEditImageModal, setOpenBlockAddEditImageModal] =
    useState(false);
  const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
    useState(false);

  const {
    data: images,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: 'images',
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/contract/file/index?block_id=${data?.id}`
      );
      return response.data.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {data && data?.id && (
          <span className='mr-4'>{t('common.fields.uploadedFiles')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className='py-3 max-w-[500px] min-w-[400px]'>
          <div className='text-end mb-3'>
            <Button
              color='success'
              variant='contained'
              disabled={isLoading || isFetching}
              onClick={() => {
                setCurrLayoutData({});
                setOpenBlockAddEditImageModal(true);
              }}
            >
              {t('common.fields.uploadFile')}
            </Button>
          </div>
          {isLoading || isFetching ? (
            <div className='circular-progress-box py-5'>
              <CircularProgress size={35} />
            </div>
          ) : images && images.length > 0 ? (
            <Grid container spacing={2} columns={{ xs: 12, sm: 12 }}>
              {images.map((image, index) => (
                <Grid item={true} sm={12} xs={12} key={`block-image-${index}`}>
                  <div className='flex flex-row items-center'>
                    <div className='bg-base-color-light-f-15 p-2 rounded-lg w-full'>
                      <b>{index + 1}.</b> {image?.name}
                    </div>
                    <Button
                      type='button'
                      variant='actionLarge'
                      className='!mx-2'
                      onClick={() => {
                        setCurrLayoutImageLink(image?.file);
                        setOpenHomeLayoutImageDialog(true);
                      }}
                    >
                      <i className='bi bi-image' />
                    </Button>
                    <Button
                      variant='actionLarge'
                      color='warning'
                      onClick={() => {
                        setCurrLayoutData(image);
                        setOpenBlockAddEditImageModal(true);
                      }}
                    >
                      <i className='bi bi-pencil-square' />
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <span className='no-data-found-wrapper my-5'>
              <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          )}
        </div>

        {openHomeLayoutImageDialog && (
          <ImagePreviewDialog
            open={openHomeLayoutImageDialog}
            setOpen={setOpenHomeLayoutImageDialog}
            url={currLayoutImageLink}
          />
        )}

        {openBlockAddEditImageModal && (
          <BlockAddEditImageModal
            open={openBlockAddEditImageModal}
            setOpen={setOpenBlockAddEditImageModal}
            data={data}
            itemData={currLayoutData}
            refetchFn={refetch}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BlockImageModal;
