import { Checkbox, Typography } from '@mui/material';
import { memo } from 'react';

import { useSearchParams } from 'react-router-dom';

const HideRepeatedCallFilter = ({ disabled = false, queries, label }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRepairTypeChange = (value) => {
    searchParams.set('hide_repeated_call', value);

    setSearchParams(searchParams);
  };

  return (
    <div className='mt-4 w-full flex items-center' style={{ borderRadius: 10 }}>
      <label htmlFor='checkbox-for-repeat'>
        <Checkbox
          size='small'
          id='checkbox-for-repeat'
          checked={searchParams.get('hide_repeated_call') == 'true'}
          onChange={(e) => handleRepairTypeChange(e.target.checked)}
        />
        <Typography
          variant='caption'
          fontSize={12}
          lineHeight={10}
          className='!cursor-pointer !whitespace-normal !leading-[10px]'
        >
          {label}
        </Typography>
      </label>
    </div>
  );
};
export default memo(HideRepeatedCallFilter);
