import useMenu from 'hooks/useMenu';
import { PermissionContext } from 'context';

const PermissionProvider = ({ children, parentCode }) => {
  const { hasChild } = useMenu();

  const hasPermission = (value) => {
    return hasChild(parentCode, value);
  };

  return (
    <PermissionContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
