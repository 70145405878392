import moment from 'moment';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CRMEvents = ({
  data = {},
  getActionName = () => {},
  getStaffName = () => {},
  getVaronkaColorName = () => {},
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(data?.list?.length < 2);

  return (
    <div className={'flex items-center relative mb-3'} id={`task-${data?.id}`}>
      <div className='w-full'>
        <div className='text-[12px] text-gray-500 flex flex-col gap-3 font-medium'>
          {isExpanded ? (
            data?.list?.map((e) => (
              <div key={e?.id}>
                {moment(data?.created_at).format('DD.MM.YYYY HH:mm')}&nbsp;
                <span className='font-semibold text-gray-700'>
                  {e?.staff?.id ? e?.staff?.name : t('common.table.system')}
                </span>
                &nbsp;
                {getActionName(e?.actionType)}:&nbsp;
                {e?.actionType === 'CHANGE_STATUS' ||
                e?.actionType === 'CONNECT_STATUS' ? (
                  <div className='-mb-1.5 h-[25px] inline-block leading-[25px] rounded-full overflow-hidden'>
                    {e?.previous ? (
                      <span
                        className='font-semibold text-white p-3 py-2'
                        style={{
                          background:
                            getVaronkaColorName(Number(e?.previous)).color ||
                            '#87898e',
                        }}
                      >
                        {getVaronkaColorName(Number(e?.previous))?.blockName}
                        {' | '}
                        {getVaronkaColorName(Number(e?.previous))?.name}
                      </span>
                    ) : (
                      ''
                    )}
                    <span
                      className='font-semibold text-white p-3 py-2'
                      style={{
                        background:
                          getVaronkaColorName(Number(e?.next)).color ||
                          '#87898e',
                      }}
                    >
                      {getVaronkaColorName(Number(e?.next))?.blockName}
                      {' | '}
                      {getVaronkaColorName(Number(e?.next))?.name}
                    </span>
                  </div>
                ) : (
                  <span className='font-semibold text-gray-700'>
                    {e?.actionType === 'LEAD_STAFF_CHANGE'
                      ? getStaffName(Number(e?.next))
                      : e?.next}
                  </span>
                )}
              </div>
            ))
          ) : (
            <div>
              {moment(data?.created_at).format('DD.MM.YYYY HH:mm')}&nbsp;
              {t('crm.leadHistory.changes')}:&nbsp;
              {data?.list?.length} {t('crm.leadHistory.count')}&nbsp;
              <span
                onClick={() => setIsExpanded(true)}
                className='text-gray-700 underline cursor-pointer font-medium'
              >
                {t('crm.leadHistory.showAll')}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(CRMEvents);
