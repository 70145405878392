import { ButtonBase, CircularProgress, Grid } from '@mui/material';
// import ObjectSelectBox from 'components/boss/ObjectSelectBox';
import ObjectBlockMultiFilter from 'components/ui/filters/items/ObjectBlockMultiFilter';
import DoughnutChart from 'components/ui/charts/DoughnutChart';
import useAuth from 'hooks/useAuth';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useCurrency from 'hooks/useCurrency';
import useTopPanel from 'hooks/useTopPanel';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useQueries } from 'react-query';
import { doughnutTooltip } from 'utils/chartjsTooltips';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [{ user }] = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { currencyData } = useCurrency();
  const [hasError, setHasError] = useState(false);
  const [dailyStatusSum, setDailyStatusSum] = useState(0);
  const { search } = useLocation();

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('boss.dashboard.title')}</div>
    );
  }, [i18n.language]);

  const [analyticData, dailyPayments] = useQueries([
    {
      queryKey: ['analyticDashboard', search],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/analytic/dashboard/index${search || ''}`
        );
        return response.data.data;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: 'dailyPayments',
      queryFn: async function () {
        const response = await axiosPrivate.get('/dictionary/daily');
        return response.data.data;
      },
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setDailyStatusSum(
            data.reduce((acc, curr) => acc + parseFloat(curr.sum), 0)
          );
        }
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  return (
    <div className='component-wrapper boss-dashboard-wrapper'>
      <div className='dashboard-actions'>
        <div className='dashboard-owner-greeting'>
          {t('boss.dashboard.welcome')},{' '}
          <span className='text-xl font-medium'>{user?.user?.name}</span>
        </div>
        <div className='max-w-[300px] w-full'>
          <ObjectBlockMultiFilter />
          {/* <ObjectSelectBox
          {/* <ObjectSelectBox
            value={selectedObjectId}
            setValue={handleChangeObject}
            size='small'
          /> */}
        </div>
      </div>

      <div className='dashboard-debts-wrapper'>
        <Grid
          container
          spacing={{ xs: 2, sm: 2, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item={true} md={4} sm={6} xs={12}>
            <div className='price-item-wrapper monthly'>
              <div className='price-item-title'>
                {t('contract.view.header.monthlyLeft')}
              </div>
              <div className='price-item-sum'>
                {analyticData.isLoading || analyticData.isFetching ? (
                  <div className='flex my-1'>
                    <CircularProgress size={20} color='inherit' />
                  </div>
                ) : (
                  <div>
                    <div>
                      UZS:{' '}
                      <NumericFormat
                        value={analyticData?.data?.monthsum}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={2}
                        className='bg-transparent'
                        suffix={' UZS'}
                      />
                    </div>
                    <div>
                      USD:{' '}
                      <NumericFormat
                        value={analyticData?.data?.monthvalute}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={2}
                        className='bg-transparent'
                        suffix={' $'}
                      />
                    </div>
                    <div>
                      {t('common.table.total')}:{' '}
                      <NumericFormat
                        value={
                          +analyticData?.data?.monthsum +
                          +analyticData?.data?.monthvalute * currencyData?.sum
                        }
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={2}
                        className='bg-transparent'
                        suffix={' UZS'}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item={true} md={4} sm={6} xs={12}>
            <div className='price-item-wrapper total'>
              <div className='price-item-title'>
                {t('boss.dashboard.allDebts')}
              </div>
              <div className='price-item-sum'>
                {analyticData.isLoading || analyticData.isFetching ? (
                  <div className='flex my-1'>
                    <CircularProgress size={20} color='inherit' />
                  </div>
                ) : (
                  <div>
                    <div>
                      UZS:{' '}
                      <NumericFormat
                        value={analyticData?.data?.totalsum}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={2}
                        className='bg-transparent'
                        suffix={' UZS'}
                      />
                    </div>
                    <div>
                      USD:{' '}
                      <NumericFormat
                        value={analyticData?.data?.totalvalute}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={2}
                        className='bg-transparent'
                        suffix={' $'}
                      />
                    </div>
                    <div>
                      {t('common.table.total')}:{' '}
                      <NumericFormat
                        value={
                          +analyticData?.data?.totalsum +
                          +analyticData?.data?.totalvalute * currencyData?.sum
                        }
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={2}
                        className='bg-transparent'
                        suffix={' UZS'}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          {/* <Grid item={true} md={4} sm={6} xs={12}>
						<Button color="success" variant="outlined" fullWidth>
							<div>
								<div>Mijoz balansi</div>
								<div className="text-lg">
									<NumericFormat
										value={analyticData?.data?.total}
										displayType={"text"}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={3}
										className="bg-transparent"
										suffix={" UZS"}
									/>
								</div>
							</div>
						</Button>
					</Grid> */}
        </Grid>
      </div>
      <Grid
        container
        spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
        rowSpacing={1}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <Grid item={true} lg={5} md={8} sm={12} xs={12}>
          <div className='dashboard-payments'>
            <div className='payments-title'>{t('boss.payment.title')}</div>
            <div className='w-[360px] h-[330px] flex items-center mx-auto'>
              {dailyPayments.isLoading || dailyPayments.isFetching ? (
                <div className='flex w-full items-center justify-center'>
                  <CircularProgress size={30} color='inherit' />
                </div>
              ) : (
                dailyPayments.data &&
                dailyPayments.data.length > 0 && (
                  <DoughnutChart
                    chartLabels={[
                      ...dailyPayments.data.map((item) => item.name),
                    ]}
                    chartDatasets={[
                      {
                        data: [
                          ...dailyPayments.data.map((item) => ({
                            total:
                              +item.sum +
                              (item?.valute
                                ? +item.valute * currencyData?.sum
                                : 0),
                            sum: item.sum,
                            valute: item?.valute ? item.valute : 0,
                          })),
                        ],
                        borderWidth: 4,
                        hoverBorderWidth: 0,
                        hoverOffset: 15,
                        borderRadius: 4,
                      },
                    ]}
                    optionPlugins={{
                      legend: {
                        display: true,
                        position: 'bottom',
                        align: 'left',
                        reverse: false,
                        labels: {
                          usePointStyle: true,
                          pointStyle: 'rectRounded',
                        },
                      },
                      tooltip: {
                        enabled: false,
                        external: doughnutTooltip('sum', 'valute', 'total'),
                      },
                    }}
                    options={{
                      layout: {
                        padding: {
                          top: 10,
                        },
                      },
                      parsing: {
                        key: 'total',
                      },
                    }}
                  />
                )
              )}
            </div>
            <div className='payments-daily-status'>
              {t('boss.dashboard.statusToday')}:{' '}
              {dailyPayments.isLoading || dailyPayments.isFetching ? (
                <div className='flex ml-2'>
                  <CircularProgress size={20} color='inherit' />
                </div>
              ) : (
                <NumericFormat
                  value={dailyStatusSum}
                  displayType={'text'}
                  allowNegative={false}
                  thousandSeparator={' '}
                  decimalScale={3}
                  className='payments-daily-status-sum bg-transparent'
                  suffix=' UZS'
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid item={true} lg={7} md={12} sm={12} xs={12}>
          <div className='dashboard-homes'>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid item={true} lg={6} md={4} sm={6} xs={12}>
                <ButtonBase className='homes-item-wrapper free-homes'>
                  <div className='homes-item-number'>
                    {analyticData.isLoading || analyticData.isFetching ? (
                      <div className='flex my-2'>
                        <CircularProgress size={32} color='inherit' />
                      </div>
                    ) : (
                      <NumericFormat
                        value={analyticData?.data?.freehomes}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={3}
                        className='bg-transparent'
                      />
                    )}
                  </div>
                  <div className='homes-item-status-name'>
                    {t('common.filter.emptyHomesCount')}
                  </div>
                </ButtonBase>
              </Grid>
              <Grid item={true} lg={6} md={4} sm={6} xs={12}>
                <ButtonBase className='homes-item-wrapper sold-homes'>
                  <div className='homes-item-number'>
                    {analyticData.isLoading || analyticData.isFetching ? (
                      <div className='flex my-2'>
                        <CircularProgress size={32} color='inherit' />
                      </div>
                    ) : (
                      <NumericFormat
                        value={analyticData?.data?.dailycontracts}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={3}
                        className='bg-transparent'
                      />
                    )}
                  </div>
                  <div className='homes-item-status-name'>
                    {t('boss.dashboard.soldToday')}
                  </div>
                </ButtonBase>
              </Grid>
              <Grid item={true} lg={12} md={4} sm={6} xs={12}>
                <ButtonBase className='homes-item-wrapper ordered-homes'>
                  <div className='homes-item-number'>
                    {analyticData.isLoading || analyticData.isFetching ? (
                      <div className='flex my-2'>
                        <CircularProgress size={32} color='inherit' />
                      </div>
                    ) : (
                      <NumericFormat
                        value={analyticData?.data?.orderhomes}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={3}
                        className='bg-transparent'
                      />
                    )}
                  </div>
                  <div className='homes-item-status-name'>
                    {t('boss.dashboard.orderedToday')}
                  </div>
                </ButtonBase>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default memo(Dashboard);
