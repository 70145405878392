import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SettingTwoIcon from './chartContainer/SettingTwoIcon';
import StatisticsOfEmployee from './statisticsOfEmployee/StatisticsOfEmployee';
// import CreatePlanModal from './createPlanModal/CreatePlanModal';
import ChartContainer from './chartContainer/ChartContainer';
import { CircularProgress } from '@mui/material';
import { planData } from '../mock';

const Chart = () => {
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const isLoading = false;

  const { t } = useTranslation();

  const data = planData.data;
  // handle open plan modal
  const handlePlanModal = (visible) => {
    setOpenPlanModal(visible);
  };

  return (
    <div
      className={
        'w-[36%] border border-[#eaeaea] py-[24px] px-[32px] rounded-lg'
      }
    >
      {isLoading ? (
        <div className='h-[260px] flex items-center justify-center'>
          <CircularProgress size={30} color='inherit' />
        </div>
      ) : (
        <>
          <div className={'flex items-center justify-between'}>
            <h2>
              {t('boss.crm.general.planFact')} {data?.pipeName}{' '}
              {t('common.filter.crm_block_id')}
            </h2>
            <SettingTwoIcon
              onClick={() => handlePlanModal(true)}
              className={'cursor-pointer'}
            />
          </div>
          <ChartContainer data={data} />
          <StatisticsOfEmployee data={data} />
          {/* <CreatePlanModal
            factPipe={data}
            visible={openPlanModal}
            close={handlePlanModal}
          /> */}
        </>
      )}
    </div>
  );
};

export default memo(Chart);
