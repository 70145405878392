import React from 'react';
import ProgressItem from './progress-items';
import Filters from './filter-items';
import AllCalls from './table';
import { useTranslation } from 'react-i18next';

function CallInfoProgress({ data, dataCallHistory, isFetchingCall }) {
  const { t } = useTranslation();
  const formattedData =
    data?.data?.map(
      ({ income, outcome }) => Number(income) + Number(outcome)
    ) || [];
  const income =
    data?.data?.reduce((acc, { income }) => acc + Number(income), 0) || 0;
  const outcome =
    data?.data?.reduce((acc, { outcome }) => acc + Number(outcome), 0) || 0;

  const max = Math.max(...formattedData) || 0;
  const totaltalk =
    data?.data?.reduce((acc, { totaltalk }) => acc + Number(totaltalk), 0) || 0;

  return (
    <div className='container'>
      <div className='income-outcome-statistics'>
        {data?.data?.map((item, i) => (
          <ProgressItem
            key={i}
            item={item}
            maximum={max}
            color={colors[i % 11]}
          />
        ))}
        <ProgressItem
          item={{ name: t('common.table.total'), income, outcome, totaltalk }}
          no_hours
          maximum={income + outcome}
          color={colors[4]}
        />
      </div>
      <div className='filter-by-call-type'>
        <Filters data={data?.types} />
      </div>
      <div className='table-calls-history'>
        <AllCalls data={dataCallHistory} isFetching={isFetchingCall} />
      </div>
    </div>
  );
}

export default CallInfoProgress;

export const colors = [
  '#ffbe0b',
  '#00f5d4',
  '#57cc99',
  '#00bbf9',
  '#8338ec',
  '#ff006e',
  '#3a86ff',
  '#fb5607',
  '#fca311',
  '#00b4d8',
  '#ef233c',
];
