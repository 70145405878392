import { ButtonBase, CircularProgress } from '@mui/material';
import DoughnutChart from 'components/ui/charts/DoughnutChart';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useQuery } from 'react-query';
import DateSelectBox from './DateSelectBox';
import moment from 'moment';
import colorGenerator from 'utils/colorGenerator';
import useCurrency from 'hooks/useCurrency';
import { doughnutTooltip } from 'utils/chartjsTooltips';

const StartingPrices = () => {
  const { t } = useTranslation();
  const { currencyData } = useCurrency();
  const axiosPrivate = useAxiosPrivate();
  // const [hasError, setHasError] = useState(false)
  const [startingPricesData, setStartingPricesData] = useState([]);
  const [bgColors, setBgColors] = useState([]);
  const [summedPrices, setSummedPrices] = useState({
    sum: 0,
    valute: 0,
  });
  const [startAndFinishDate, setStartAndFinishDate] = useState({
    startDate: moment()
      .set('years', 2010)
      .startOf('years')
      .format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
  });

  const { isLoading, isFetching, isError } = useQuery({
    queryKey: ['startingPrices', startAndFinishDate],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/object/startprice?from=${startAndFinishDate.startDate}&till=${startAndFinishDate.finishDate}`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStartingPricesData(data);
        setSummedPrices(
          data.reduce(
            (acc, curr) => ({
              sum: acc?.sum + +curr?.sum,
              valute: acc?.valute + +curr?.valute,
            }),
            {
              sum: 0,
              valute: 0,
            }
          )
        );
        if (!bgColors.length) {
          setBgColors(
            Array.from({ length: data.length }, () => colorGenerator())
          );
        }
      }
    },
    // enabled: !hasError,
    // onError: (error) => {
    // 	setHasError(true)
    // },
    retry: false,
  });

  return (
    <div className='residential-complex-item starting-prices'>
      <div className='residential-complex-item-header'>
        <div className='residential-complex-item-title starting-prices-title'>
          {t('boss.residentialComplex.startPayments')}
        </div>
        <div className='w-[150px]'>
          <DateSelectBox
            setStartAndFinishDate={setStartAndFinishDate}
            disabled={isLoading || isFetching}
          />
        </div>
      </div>

      <div className='starting-prices-body'>
        <div className='starting-prices-body-details'>
          <div className='starting-prices-body-title'>
            {t('boss.residentialComplex.allStartPayments')}
          </div>
          {isLoading || isFetching ? (
            <div className='flex mt-1.5'>
              <CircularProgress size={15} color='inherit' />
            </div>
          ) : (
            <div>
              <NumericFormat
                value={
                  +summedPrices?.sum + +summedPrices?.valute * currencyData?.sum
                }
                displayType={'text'}
                allowNegative={false}
                thousandSeparator={' '}
                decimalScale={3}
                className='starting-prices-total-sum bg-transparent'
                suffix=' UZS'
              />
              <div>
                <div>
                  UZS:{' '}
                  <NumericFormat
                    value={summedPrices?.sum}
                    displayType={'text'}
                    allowNegative={false}
                    thousandSeparator={' '}
                    decimalScale={3}
                    className='starting-prices-total-sum-part bg-transparent'
                    suffix=' UZS'
                  />
                </div>
                <div>
                  USD:{' '}
                  <NumericFormat
                    value={summedPrices?.valute}
                    displayType={'text'}
                    allowNegative={false}
                    thousandSeparator={' '}
                    decimalScale={3}
                    className='starting-prices-total-sum-part bg-transparent'
                    suffix=' $'
                  />
                </div>
              </div>
            </div>
          )}
          <div className='starting-prices-of-objects'>
            {isLoading || isFetching ? (
              <div className='h-[100px] flex items-center justify-center'>
                <CircularProgress size={25} color='inherit' />
              </div>
            ) : startingPricesData.length > 0 ? (
              startingPricesData.map((item, index) => (
                <ButtonBase
                  className={`object-item-wrapper ${
                    index % 2 == 0 ? 'item-even' : 'item-odd'
                  }`}
                  key={`object-item-${index}`}
                >
                  <div className='object-item-body'>
                    <div className='item-title'>
                      <div
                        className='item-indicator'
                        style={{
                          '--current-indicator-bg-color': bgColors[index],
                        }}
                      ></div>
                      {item?.name}
                    </div>
                    <div className='item-value'>
                      <NumericFormat
                        value={item?.sum}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={3}
                        className='bg-transparent mb-1'
                        suffix=' UZS'
                      />
                      <NumericFormat
                        value={item?.valute}
                        displayType={'text'}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalScale={3}
                        className='bg-transparent mt-1'
                        suffix=' $'
                      />
                    </div>
                  </div>
                </ButtonBase>
              ))
            ) : (
              <div>
                <span className='no-data-found-wrapper'>
                  <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                  {t('common.global.noDataFound')}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className='starting-prices-chart'>
          {isError ? (
            <div>
              <span className='no-data-found-wrapper'>
                <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                {t('common.global.noDataFound')}
              </span>
            </div>
          ) : startingPricesData.length > 0 ? (
            <DoughnutChart
              chartLabels={[...startingPricesData.map((item) => item?.name)]}
              chartDatasets={[
                {
                  data: [
                    ...startingPricesData.map((item) => ({
                      total:
                        +item.sum +
                        (item?.valute ? +item.valute * currencyData?.sum : 0),
                      sum: item.sum,
                      valute: item?.valute ? item.valute : 0,
                    })),
                  ],
                  backgroundColor: bgColors,
                  borderWidth: 2,
                  hoverOffset: 15,
                  hoverBorderWidth: 0,
                  borderRadius: 4,
                },
              ]}
              optionPlugins={{
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                  external: doughnutTooltip('sum', 'valute', 'total'),
                },
              }}
              options={{
                layout: {
                  padding: {
                    top: 10,
                    bottom: 10,
                  },
                },
                parsing: {
                  key: 'total',
                },
              }}
            />
          ) : (
            <CircularProgress size={30} color='inherit' />
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(StartingPrices);
