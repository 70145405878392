import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useDebounce from 'hooks/useDebounce';
import { t } from 'i18next';
import { colors } from 'shared/colors';

export default function LeadConnectorModal({
  open,
  handleClose,
  connecting,
  clientRefetch,
}) {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoDataFound, setIsNoDataFound] = useState(false);
  const [searchTxt, setSearchTxt] = useState('');
  const [selected, setSelected] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const searchTxtDebounced = useDebounce(searchTxt, 300);

  const handleSearch = async () => {
    if (searchTxtDebounced && searchTxtDebounced.length >= 3) {
      setIsLoading(true);
      setIsNoDataFound(false);
      try {
        let response = await axiosPrivate.post(
          '/crm/crm-lead/search',
          JSON.stringify({ search: searchTxtDebounced }),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.data
        ) {
          setSearchResults(response.data.data);
          setIsNoDataFound(response.data.data.length == 0 || false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = async (event) => {
    if (event.keyCode == 13) {
      await handleSearch();
    }
  };

  const getHighlightedValue = (value) => {
    return value && value.length > 0
      ? value.replaceAll(
          searchTxtDebounced,
          `<span class="bg-base-color-disabled text-white">${searchTxtDebounced}</span>`
        )
      : '';
  };

  useEffect(() => {
    if (searchTxtDebounced) {
      handleSearch();
    } else {
    }
  }, [searchTxtDebounced]);

  const onClose = () => {
    handleClose();
    setSelected({});
    setSearchTxt('');
    setIsLoading(false);
    setIsNoDataFound(false);
    setSearchResults([]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{ sx: { width: 500 } }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {/* <span>{t("client.login.addTitle")}</span> */}
        <span>{t('client.leadConnector.connectModal.title')}</span>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={onClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div>
          <TextField
            label={t('client.leadConnector.connectModal.filter')}
            fullWidth
            value={searchTxt}
            handleKeyDown={handleKeyDown}
            onChange={({ target }) => setSearchTxt(target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className='custom-endAdornment'>
                  {searchTxt ? (
                    <IconButton
                      onClick={() => {
                        setSearchTxt('');
                        setSearchResults([]);
                      }}
                      variant='onlyIcon'
                    >
                      <i className='bi bi-x end-adornment-close-button' />
                    </IconButton>
                  ) : (
                    <IconButton variant='onlyIcon' disabled disableRipple>
                      <i className='bi bi-search text-base leading-4' />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused i': {
                color: colors.baseColorLight,
              },
              '& .MuiOutlinedInput-root i': {
                color: colors.grayColor,
              },
            }}
          />
        </div>
        <div className='max-h-[300px] h-full overflow-y-auto my-scroll'>
          {isLoading ? (
            <div className='circular-progress-box py-5'>
              <CircularProgress size={35} />
            </div>
          ) : selected?.id ? (
            <div
              className='border border-[#17a7f0] py-2 px-3 rounded-lg mb-2 bg-gray-100 cursor-pointer hover:bg-gray-200'
              onClick={() => setSelected({})}
            >
              <div>
                <div className='text-gray-900 text-sm leading-5'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getHighlightedValue(selected?.name),
                    }}
                  />
                  {selected?.contacts &&
                    selected?.contacts.length > 0 &&
                    selected?.contacts.map((contact, contactInd) => (
                      <div
                        key={`item-contact-${contactInd}`}
                        className='text-gray-700 text-[12px] leading-4'
                      >
                        {contact?.phones &&
                          contact?.phones.length > 0 &&
                          contact?.phones.map((phone, phoneInd) => (
                            <div
                              key={`contact-${contactInd}-phone-${phoneInd}`}
                              dangerouslySetInnerHTML={{
                                __html: getHighlightedValue(phone?.phone),
                              }}
                            />
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : searchResults && searchResults.length > 0 ? (
            searchResults.map((item, index) => (
              <div
                className='border border-[#E5E9EB] py-2 px-3 rounded-lg mb-2 cursor-pointer hover:bg-gray-200'
                key={`search-result-item-${index}`}
                onClick={() => setSelected(item)}
              >
                <div>
                  <div className='text-gray-900 text-sm leading-5'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getHighlightedValue(item?.name),
                      }}
                    />
                    {item?.contacts &&
                      item?.contacts.length > 0 &&
                      item?.contacts.map((contact, contactInd) => (
                        <div
                          key={`item-contact-${contactInd}`}
                          className='text-gray-700 text-[12px] leading-4'
                        >
                          {contact?.phones &&
                            contact?.phones.length > 0 &&
                            contact?.phones.map((phone, phoneInd) => (
                              <div
                                key={`contact-${contactInd}-phone-${phoneInd}`}
                                dangerouslySetInnerHTML={{
                                  __html: getHighlightedValue(phone?.phone),
                                }}
                              />
                            ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            isNoDataFound && (
              <div>
                <span className='no-data-found-wrapper select-box my-2'>
                  <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                  {t('common.global.noDataFound')}
                </span>
              </div>
            )
          )}
        </div>
        {selected?.id ? (
          <div className='flex justify-end gap-1'>
            <Button variant='outlined' onClick={onClose} color='primary'>
              {t('common.button.cancel')}
            </Button>
            <Button
              variant='contained'
              onClick={() =>
                connecting(selected, () => {
                  onClose();
                  clientRefetch();
                })
              }
              color='primary'
            >
              {t('common.button.connect')}
            </Button>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
