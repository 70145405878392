import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormFileUploadField from 'components/ui/form/FormFileUploadField';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup
    .string()
    // .min(4, { label: 'block.imageAddEditModal.validation.nameMin', value: 4 })
    .required('settings.targets.validation.name'),
  file: yup.mixed().nullable(),
});

const BlockAddEditImageModal = (props) => {
  const { open, setOpen, data, itemData, refetchFn = () => {} } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();
  const getName = (str) => {
    if (!str) return null;
    const words = str.split('/');
    return words?.at(-1);
  };

  const formik = useFormik({
    initialValues: {
      name: itemData?.name || '',
      file: getName(itemData?.link) || null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = Object.fromEntries(
        Object.entries(values)?.map(([a, b]) =>
          a !== 'name' ? (typeof b == 'string' ? [a, null] : [a, b]) : [a, b]
        )
      );
      if (itemData && itemData?.id) {
        submit(
          { type: 'postUpdate', contentType: 'formData' },
          body,
          '/admin/contract/file',
          // {
          //   title: t('block.imageAddEditModal.alerts.success', {
          //     objectName: data?.objects?.name,
          //     blockName: data?.name,
          //   }),
          // },
          null,
          itemData?.id,
          false,
          handleFinish
        );
      } else {
        submit(
          { type: 'post', contentType: 'formData' },
          { ...body, block_id: data?.id },
          '/admin/contract/file',
          // {
          //   title: t('block.imageAddEditModal.alerts.success', {
          //     objectName: data?.objects?.name,
          //     blockName: data?.name,
          //   }),
          // },
          null,
          null,
          false,
          handleFinish
        );
      }
    },
  });

  const handleFinish = () => {
    refetchFn();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {itemData && itemData?.id ? (
          <span>
            {t('common.fields.uploadFile', {
              value: itemData?.name,
            })}
          </span>
        ) : (
          <span>{t('common.fields.uploadFile')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className='py-3 max-w-[700px]'>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              <Grid item={true} sm={6} xs={12}>
                <div className='-mb-1 text-gray-600 text-sm'>
                  {t('common.fields.simpleName')}
                </div>
                <FormTextField
                  delay={0.001}
                  placeholder={t('common.fields.simpleName')}
                  fieldName='name'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} sm={6} xs={12}>
                <div className='-mb-1 text-gray-600 text-sm'>
                  {t('common.fields.uploadFile')}
                </div>
                <FormFileUploadField
                  delay={0.0013}
                  accept='.jpg, .png'
                  fieldName='file'
                  formik={formik}
                  label={t('common.fields.uploadFile')}
                  btnLabel={t('common.fields.uploadFile')}
                  fileSize={1}
                />
              </Grid>

              <Grid item={true} sm={12} xs={12}>
                <FormActionButtons
                  delay={0.4}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  reset={formik.resetForm}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BlockAddEditImageModal;
