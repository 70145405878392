import React, { memo, useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueries, useQuery } from 'react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import Order from './Order';

const validationSchema = yup.object({
  name: yup.string().min(3).required(),
  crm_block_id: yup.string().nullable(),
  crm_block_item_id: yup.string(),
  user_id: yup.string().nullable(),
  budget: yup.number().nullable(),
});

export default memo(function OrderLeadModal({
  open,
  setOpen,
  checkedList,
  setCheckedList,
  refetch,
  setRefetchGetField,
}) {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const { submit, isSubmitting } = useFormSubmit();
  const [hasError, setHasError] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      crm_block_id: '',
      crm_block_item_id: '',
      user_id: '',
      budget: '',
      leads: checkedList,
      fields: [],
      contacts: [
        {
          name: '',
          phones: [
            {
              phone: '',
            },
          ],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      submit(
        { type: 'put', contentType: 'simple' },
        values,
        '/crm/crm-lead/combine-leads',
        values.name,
        null,
        true,
        refetch
      );

      setRefetchGetField(true);
    },
  });

  const handleClose = () => {
    setCheckedList([id]);
    setOpen(false);
  };

  const queries = useQueries(
    checkedList?.map((id, i) => ({
      queryKey: ['/crm/crm-lead/edit2/{id}', id],
      queryFn: async function () {
        const response = await axiosPrivate.get(`/crm/crm-lead/edit2/${id}`);
        return response.data.data;
      },
      onSuccess: (res) => {
        const data = res?.lead;
        if (data && !i) {
          formik.setValues(
            {
              name: data?.name || '',
              crm_block_id: data?.crm_block_id || '',
              crm_block_item_id: data?.crm_block_item_id || '',
              user_id: data?.user_id || '',
              budget: data?.budget ?? '',
              fields: [],
            },
            true
          );
          setRefetchGetField(true);
        }
      },
      enabled: !hasError && !!id,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    }))
  );

  const {
    data: fieldList,
    isLoading: isLoadingFields,
    isFetching: isFetchingFields,
  } = useQuery({
    queryKey: ['/crm/crm-settings/fields'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/crm-settings/fields');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (res) => {
      if (!id) {
        const fields = res?.map(({ id }) => ({ field_id: id, value: '' }));
        formik.setFieldValue('fields', fields);
      }
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const getFields = useQueries(
    checkedList?.map((id) => ({
      queryKey: ['/crm/crm-lead/getfields/id', id, fieldList],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/crm/crm-lead/getfields/${id}`
        );
        return response.data.data;
      },
      enabled: !hasError && checkedList?.length > 0 && fieldList?.length > 0,

      onError: () => {
        setHasError(true);
      },
      retry: false,
    }))
  );

  const dataList = useMemo(() => {
    if (queries?.every((f) => !(f?.isFetching || f?.isLoading || !f?.data))) {
      const list = queries?.map((query) => query?.data?.lead);

      return list;
    }
    return [];
  }, [queries]);

  const fieldFilteredList = useMemo(() => {
    if (getFields?.every((f) => !(f?.isFetching || f?.isLoading || !f?.data))) {
      const newFields = fieldList?.filter((field) =>
        getFields?.some(
          (f) => f?.data?.find((d) => d?.field_id == field?.id)?.value
        )
      );

      return newFields;
    }
    return [];
  }, [fieldList, getFields]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{
        sx: { minWidth: 1100, boxShadow: 'none' },
        className: '!base-border',
      }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='order-view-dialog-title' className='!bg-gray-100'>
        <div className='text-xl text-center'>{t('crm.combine.title')}</div>
      </DialogTitle>

      <DialogContent>
        <div className='my-2'>
          <div className='text-label text-sm max-w-[560px]'>
            {t('crm.combine.description')}
          </div>
          <div className='h-[calc(100vh-400px)] overflow-y-auto my-scroll'>
            <Order
              formik={formik}
              isLoadingFields={isLoadingFields}
              isFetchingFields={isFetchingFields}
              fieldFilteredList={fieldFilteredList}
              getFields={getFields}
              queries={queries}
              dataList={dataList}
              checkedList={checkedList}
            />
          </div>
        </div>
        <div className='mb-4 mt-6'>
          <Divider />
        </div>
        <div className='flex justify-end items-center gap-2'>
          <Button variant='outlined' color='error' onClick={handleClose}>
            {t('common.button.cancel')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={formik.handleSubmit}
            disabled={checkedList?.length < 2 || isSubmitting}
          >
            {t('common.button.save')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});
