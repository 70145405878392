import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ContractPaymentSimpleListModal = (props) => {
  const { paymentsListQuery, data: contractData } = props;
  const { t } = useTranslation();

  const setPaymentStatus = (sum, left, date) => {
    if (sum && left?.toString()) {
      if (
        parseInt(sum) == 0 &&
        parseInt(left) == 0 &&
        moment(date) < moment()
      ) {
        return (
          <Chip
            label={t('contract.modal.paymentList.action.paid')}
            variant='tableBadge'
            color='success'
          />
        );
      } else if (parseInt(sum) !== 0 && parseInt(left) == 0) {
        return (
          <Chip
            label={t('contract.modal.paymentList.action.paid')}
            variant='tableBadge'
            color='success'
          />
        );
      } else if (parseInt(sum) - parseInt(left) !== 0) {
        return (
          <Chip
            label={t('contract.modal.paymentList.action.halfPaid')}
            variant='tableBadge'
            color='warning'
          />
        );
      } else {
        return (
          <Chip
            label={t('contract.modal.paymentList.action.notPaid')}
            variant='tableBadge'
            color='error'
          />
        );
      }
    }
    return '';
  };

  const setPaymentColor = (sum, left, date) => {
    if (sum && left?.toString()) {
      if (
        parseInt(sum) == 0 &&
        parseInt(left) == 0 &&
        moment(date) < moment()
      ) {
        return 'text-green-600';
      } else if (parseInt(sum) !== 0 && parseInt(left) == 0) {
        return 'text-green-600';
      } else if (parseInt(sum) - parseInt(left) !== 0) {
        return 'text-orange-400';
      } else {
        return 'text-red-600';
      }
    }
    return '';
  };

  return (
    <div className='mt-2'>
      {contractData?.id && (
        <TableContainer className='base-table !shadow-none base-border flex-auto h-full'>
          <Table
            stickyHeader
            sx={{ minWidth: 750, height: 'max-content' }}
            aria-labelledby='tableTitle'
          >
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{t('common.table.date')}</TableCell>
                <TableCell>{t('common.table.sum')}</TableCell>
                <TableCell>{t('common.table.paid')}</TableCell>
                <TableCell>{t('common.table.status')}</TableCell>
              </TableRow>
            </TableHead>
            {paymentsListQuery?.isLoading || paymentsListQuery?.isFetching ? (
              <TableBody className='overflow-hidden'>
                <TableRow>
                  <TableCell colSpan={5}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : paymentsListQuery?.isError ? (
              <TableBody className='overflow-hidden'>
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className='flex flex-col items-center'>
                      {paymentsListQuery?.error?.response?.data?.message && (
                        <span className='text-red-600 font-medium'>
                          {paymentsListQuery?.error?.response?.data?.message}
                        </span>
                      )}
                      {paymentsListQuery?.error?.response?.data?.details &&
                        paymentsListQuery?.error?.response?.data?.details[0]
                          ?.message && (
                          <div>
                            <span className='text-red-600 font-medium'>
                              {t('common.errors.message')}
                            </span>
                            <span>
                              {
                                paymentsListQuery?.error?.response?.data
                                  ?.details[0]?.message
                              }
                            </span>
                          </div>
                        )}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : paymentsListQuery?.data &&
              paymentsListQuery?.data.length > 0 ? (
              <TableBody className='overflow-hidden'>
                {paymentsListQuery?.data.map((row, rowIndex) => {
                  return (
                    <TableRow hover tabIndex={-1} key={'row-' + rowIndex}>
                      <TableCell>{rowIndex + 1}</TableCell>
                      <TableCell>
                        {moment(row.payment_date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <CurrencyFormat
                          value={row.sum}
                          suffix={
                            contractData?.isvalute == '0' ||
                            (contractData?.isvalute == '2' &&
                              row?.default == '1')
                              ? ' UZS'
                              : ' $'
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <CurrencyFormat
                          value={row.sum - row.left}
                          className={`${setPaymentColor(
                            row.sum,
                            row.left,
                            row.payment_date
                          )}`}
                          suffix={
                            contractData?.isvalute == '0' ||
                            (contractData?.isvalute == '2' &&
                              row?.default == '1')
                              ? ' UZS'
                              : ' $'
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {setPaymentStatus(row.sum, row.left, row.payment_date)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5}>
                    <span className='text-gray-400 flex items-center justify-center'>
                      <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                      {t('common.global.noDataFound')}
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default memo(ContractPaymentSimpleListModal);
