import React, { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import { t } from 'i18next';
import { StarNoThingIcon, SuperStarIcons } from 'components/styledIcons';
import { Trans } from 'react-i18next';

const sidebar = {
  open: () => {
    return {
      width: '100%',
      height: '100%',
      background: '#fef08a',
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 30,
      },
    };
  },
  closed: {
    width: '56px',
    height: '32px',
    background: 'transparent',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 100, velocity: -10 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

export default function AnimatedDiscountInfo({ data, from }) {
  const [isOpen, setIsleOpen] = useState(false);

  const sumData = useMemo(() => {
    if (data?.isvalute == 2) {
      return {
        sum: data?.totalsum,
        totalsum: data?.sum,
        valute: data?.totalvalute,
      };
    }
    return data?.sum;
  }, [data?.isvalute, data?.totalsum, data?.totalvalute, data?.sum]);

  const realPrice =
    from === 'sum' ? sumData : (data?.sum / data?.square).toFixed(1);
  const discount =
    from === 'sum'
      ? data?.discount
      : (data?.discount / data?.square).toFixed(1);
  const currentPrice =
    from === 'sum'
      ? data?.sum - data?.discount
      : ((data?.sum - data?.discount) / data?.square)?.toFixed(1);
  const discountPercentage = Math.floor((data?.discount / data?.sum) * 100);

  return (
    <div>
      <motion.div
        animate={isOpen ? 'open' : 'closed'}
        className='absolute cursor-pointer top-0 right-0 z-10 rounded-bl-[15px]'
        variants={sidebar}
        onMouseLeave={() => setIsleOpen(false)}
        onClick={(e) => {
          e.stopPropagation();
          if (discountPercentage) setIsleOpen(true);
        }}
      >
        {!isOpen ? (
          <div className='w-14 h-8 flex items-center justify-center absolute top-0 right-0 font-semibold'>
            <span className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[9px] font-semibold'>
              {discountPercentage ? discountPercentage + '%' : ''}
            </span>
            {discountPercentage ? <SuperStarIcons /> : <StarNoThingIcon />}
          </div>
        ) : (
          ''
        )}
        <motion.div variants={variants} className='h-full p-3'>
          <motion.div
            variants={itemVariants}
            className='flex-grow mb-2 flex flex-col items-start text-[13px] opacity-0'
          >
            <span className=' text-black'>
              {t('common.fields.realPrice')}:&nbsp;
              {data?.isvalute == 2 && from == 'sum' ? (
                <>
                  <CurrencyFormat value={sumData?.sum} suffix={' UZS'} />
                  <br />
                  <CurrencyFormat value={sumData?.valute} suffix={' $'} />
                  <br />
                  <CurrencyFormat value={sumData?.totalsum} suffix={' $'} />
                </>
              ) : (
                <CurrencyFormat
                  className='font-bold text-[14px]'
                  value={realPrice}
                  suffix={data?.isvalute == '1' ? ' $' : ' UZS'}
                />
              )}
            </span>
          </motion.div>
          <motion.div
            variants={itemVariants}
            className='flex-grow flex justify-start text-start items-start text-[13px] opacity-0'
          >
            <span className=' text-black'>
              {t('shaxmatka.moreHomeDetails.discount')}:&nbsp;
              <CurrencyFormat
                className='font-bold text-[14px]'
                value={discount}
                suffix={data?.isvalute == '1' ? ' $' : ' UZS'}
              />{' '}
              <span className='py-0.5 px-2 text-white bg-pink-700 rounded-md font-semibold'>
                {discountPercentage}%
              </span>
            </span>
          </motion.div>
          {isOpen && <div className='border-b mt-2'></div>}
        </motion.div>
      </motion.div>

      <div className='flex flex-col p-2 w-full items-start'>
        <span className='text-[14px] text-[#979797] leading-5 mb-1'>
          {from === 'sum' ? (
            t('contract.view.header.sum')
          ) : (
            <Trans i18nKey='contract.view.header.priceForSquare'>
              1 m<sup>2</sup> uchun to'lov miqdori
            </Trans>
          )}
        </span>
        <span className='text-[18px] text-black text-left'>
          {data?.isvalute == 2 && from == 'sum' ? (
            <>
              <CurrencyFormat value={sumData?.sum} suffix={' UZS'} />
              <br />
              <CurrencyFormat value={sumData?.valute} suffix={' $'} />
              <br />
              {t('common.table.total')}:&nbsp;&nbsp;
              <CurrencyFormat value={sumData?.totalsum} suffix={' UZS'} />
            </>
          ) : (
            <CurrencyFormat
              value={currentPrice}
              suffix={data?.isvalute == '1' ? ' $' : ' UZS'}
            />
          )}
        </span>
        {discountPercentage ? (
          <span className='text-[12px] line-through -mt-2 text-black'>
            <CurrencyFormat
              value={realPrice}
              suffix={data?.isvalute == '1' ? ' $' : ' UZS'}
            />
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
