import PermissionProvider from 'context/providers/PermissionProvider';
import useMenu from 'hooks/useMenu';
import { memo } from 'react';
import { Navigate } from 'react-router-dom';
import MENU_PERMISSIONS_LIST from 'shared/menuPermissionsList';

const RequireAuth = ({
  permissionCode = '',
  children,
  hasChildPermission = true,
}) => {
  const { menus, hasMenu } = useMenu();

  return hasMenu(permissionCode) ? (
    hasChildPermission ? (
      <PermissionProvider parentCode={permissionCode}>
        {children}
      </PermissionProvider>
    ) : (
      children
    )
  ) : menus && menus.length > 0 ? (
    <Navigate
      to={`/${
        MENU_PERMISSIONS_LIST.TOP.find((item) => item.code == menus[0])
          ?.linkData?.path
      }`}
      replace
    />
  ) : (
    <Navigate to='/not-found' replace />
  );
};

export default memo(RequireAuth);
