import { ButtonBase, CircularProgress } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import BaseTooltip from '../ui/tooltips/BaseTooltip';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { BuildingIcon } from 'components/styledIcons';

const DashboardObjectsSlider = ({
  setObjectsError = () => {},
  setFilterData = () => {},
  setIsFilterLoading = () => {},
}) => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedObjectsId, setSelectedObjectsId] = useState([]);
  const [hasError, setHasError] = useState(false);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: 'objects',
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/object/objects');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (data) => {
      setFilterData(data);
    },
    onError: (error) => {
      setHasError(true);
      setObjectsError(true);
    },
    retry: false,
  });

  const handleObjectId = (objectId) => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item && item.includes('object')) {
        searchParams.delete(item);
      }
    });
    if (isSelected(objectId)) {
      selectedObjectsId.splice(selectedObjectsId.indexOf(objectId), 1);
    } else {
      selectedObjectsId.push(objectId);
    }
    selectedObjectsId.forEach((item, index) => {
      searchParams.set(`object[${index}]`, item);
    });
    setSearchParams(searchParams);
    setSelectedObjectsId(selectedObjectsId);
  };

  const isSelected = (objectId) => {
    return selectedObjectsId.includes(objectId);
  };

  const setDefaultObjects = () => {
    let entries = Object.fromEntries(searchParams);
    let newObjectsId = [];
    Object.keys(entries).forEach((item) => {
      if (item && item.includes('object')) {
        newObjectsId.push(parseInt(searchParams.get(item)));
      }
    });
    setSelectedObjectsId(newObjectsId);
  };

  useEffect(() => {
    setDefaultObjects();
  }, [searchParams]);

  useEffect(() => {
    setIsFilterLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  return (
    <div className='dashboard-objects-slider-wrapper'>
      {isLoading || isFetching ? (
        <div className='circular-progress-box h-[100px]'>
          <CircularProgress size={25} />
        </div>
      ) : data && data?.objects && data?.objects.length > 0 ? (
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          freeMode={true}
          // pagination={{
          // 	clickable: true
          // }}
          pagination={false}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
          modules={[FreeMode, Pagination]}
        >
          {data?.objects.map((objectItem) => (
            <SwiperSlide key={objectItem?.id}>
              <ButtonBase
                id={`object-${objectItem?.id}`}
                className={`object-item !shadow-none !p-3 !max-h-[149px] !h-[149px] !border !border-[#E7E7E7] !rounded-2xl  ${
                  isSelected(objectItem?.id)
                    ? 'item-selected !bg-base-color !text-white'
                    : '!bg-white'
                }`}
                onClick={() => handleObjectId(objectItem?.id)}
              >
                <div className='object-item-body'>
                  <div
                    className={`${
                      isSelected(objectItem?.id)
                        ? 'bg-[#F0F0FA66]'
                        : 'bg-[#F0F0FA]'
                    } p-2 rounded-lg`}
                  >
                    <BuildingIcon
                      color={
                        isSelected(objectItem?.id)
                          ? 'white'
                          : 'var(--base-color)'
                      }
                    />
                  </div>
                  <div className='object-title !leading-[14px] mb-2'>
                    <BaseTooltip
                      title={objectItem?.name}
                      arrow={true}
                      placement='top'
                      enterDelay={500}
                    >
                      <span
                        className={`${
                          isSelected(objectItem?.id)
                            ? '!text-white'
                            : '!text-gray-700'
                        } text-[14px]`}
                      >
                        {objectItem?.name}
                      </span>
                    </BaseTooltip>
                  </div>
                  <div className='object-home-type-number flex justify-between w-full'>
                    <span>{t('common.filter.homesCount')}:</span>{' '}
                    <span>{objectItem.total}</span>
                  </div>
                  <div className='object-home-type-number flex justify-between w-full'>
                    <span>{t('common.filter.emptyHomesCount')}:</span>{' '}
                    <span>{objectItem.active}</span>
                  </div>
                  <div className='object-home-type-number flex justify-between w-full'>
                    <span>{t('common.filter.orders')}:</span>{' '}
                    <span>{objectItem.order}</span>
                  </div>
                </div>
              </ButtonBase>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className='my-6'>
          <span className='no-data-found-wrapper'>
            <i className='bi bi-exclamation-octagon text-xl mr-1 leading-3' />{' '}
            {t('common.global.noBlocksFound')}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(DashboardObjectsSlider);
