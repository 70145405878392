import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import useDebounceSearch from 'hooks/useDebounceSearch';
import useSearch from 'hooks/useSearch';
import React, { memo, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

const TalktimeRangeFilter = () => {
  const query = useSearch();
  const [homeSquareFrom, setHomeSquareFrom] = useDebounceSearch(
    'talktime_from',
    '',
    300
  );
  const [homeSquareTill, setHomeSquareTill] = useDebounceSearch(
    'talktime_to',
    '',
    300
  );

  useEffect(() => {
    if (!query.get('talktime_from')) setHomeSquareFrom('');
    if (!query.get('talktime_to')) setHomeSquareTill('');
  }, [query.get('talktime_from'), query.get('talktime_to')]);

  return (
    <>
      <Typography variant='caption'>
        <Trans i18nKey='crm.leadHistory.talkDuration' />
      </Typography>
      <div className='flex flex-row'>
        <NumericFormat
          id='from'
          name='from'
          label={
            <span>
              <Trans i18nKey='common.table.from' />
            </span>
          }
          value={homeSquareFrom}
          onInput={(event) => setHomeSquareFrom(event.target?.value)}
          color='formColor'
          variant='outlined'
          fullWidth
          customInput={TextField}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' className='custom-endAdornment'>
                {homeSquareFrom && (
                  <IconButton
                    onClick={() => setHomeSquareFrom('')}
                    variant='onlyIcon'
                  >
                    <i className='bi bi-x end-adornment-close-button' />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            sx: {
              borderTopRightRadius: '0 !important',
              borderBottomRightRadius: '0 !important',
            },
          }}
          sx={{
            marginTop: '0.25rem',
            marginBottom: '0.25rem',
          }}
          allowNegative={false}
          decimalScale={0}
        />

        <NumericFormat
          id='butjet_till'
          name='butjet_till'
          label={
            <span>
              <Trans i18nKey='common.table.till' />
            </span>
          }
          value={homeSquareTill}
          onInput={(event) => setHomeSquareTill(event.target?.value)}
          color='formColor'
          variant='outlined'
          fullWidth
          customInput={TextField}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' className='custom-endAdornment'>
                {homeSquareTill && (
                  <IconButton
                    onClick={() => setHomeSquareTill('')}
                    variant='onlyIcon'
                  >
                    <i className='bi bi-x end-adornment-close-button' />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            sx: {
              borderTopLeftRadius: '0 !important',
              borderBottomLeftRadius: '0 !important',
            },
          }}
          sx={{
            marginTop: '0.25rem',
            marginBottom: '0.25rem',
          }}
          allowNegative={false}
          decimalScale={0}
        />
      </div>
    </>
  );
};

export default memo(TalktimeRangeFilter);
