import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { memo, useState } from 'react';

const radios = [
  {
    code: 'tilltoday',
    label: 'tasks.filter.date.tilltoday',
  },
  {
    code: 'today',
    label: 'tasks.filter.date.today',
  },
  {
    code: 'yesterday',
    label: 'tasks.filter.date.yesterday',
  },
  {
    code: 'week',
    label: 'tasks.filter.date.week',
  },
  {
    code: 'month',
    label: 'tasks.filter.date.month',
  },
  {
    code: 'custom',
    label: 'tasks.filter.date.custom',
  },
];

const DateTypeSelectFilter = ({
  disabled = false,
  queries,
  label,
  className,
}) => {
  const fromQuery = queries?.[0] || 'from';
  const tillQuery = queries?.[1] || 'till';
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCustomDate, setOpenCustomDate] = useState(false);

  const handleRepairTypeChange = (e) => {
    const { value } = e?.target;
    if (value == 'tilltoday') {
      searchParams.delete(fromQuery);
      searchParams.delete(tillQuery);
    }
    if (value == 'today') {
      searchParams.set(fromQuery, moment().format('YYYY-MM-DD'));
      searchParams.set(tillQuery, moment().format('YYYY-MM-DD'));
    }
    if (value == 'yesterday') {
      searchParams.set(
        fromQuery,
        moment().subtract(1, 'days').format('YYYY-MM-DD')
      );
      searchParams.set(
        tillQuery,
        moment().subtract(1, 'days').format('YYYY-MM-DD')
      );
    }
    if (value == 'week') {
      searchParams.set(
        fromQuery,
        moment().clone().startOf('isoWeek').format('YYYY-MM-DD')
      );
      searchParams.set(tillQuery, moment().format('YYYY-MM-DD'));
    }
    if (value == 'month') {
      searchParams.set(
        fromQuery,
        moment().clone().startOf('month').format('YYYY-MM-DD')
      );
      searchParams.set(tillQuery, moment().format('YYYY-MM-DD'));
    }
    if (value == 'custom') {
      return setOpenCustomDate(true);
    }
    searchParams.set('type-date-picker', value);
    setSearchParams(searchParams);
  };

  const onChangeDate = (values, searchParams) => {
    searchParams.set('type-date-picker', 'custom');
  };

  return (
    <>
      <FormControl className={className} fullWidth color='formColor'>
        <InputLabel id='single-block-filter-label'>
          {t(label || 'common.fields.staffName')}
        </InputLabel>
        <Select
          labelId='single-block-filter-label'
          id='single-block-filter-chip'
          label={t('common.fields.staffName')}
          value={searchParams.get('type-date-picker') || 'tilltoday'}
          onChange={handleRepairTypeChange}
          className={className}
          color='formColor'
          variant='outlined'
          MenuProps={{
            id: 'single-block-filter-select-menu',
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
            disableScrollLock: true,
          }}
        >
          {radios.map((item, index) => (
            <MenuItem value={item.code} key={`block-${index + 1}`}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Dialog
        open={openCustomDate}
        PaperProps={{ className: '!base-border !shadow-none' }}
        onClose={() => setOpenCustomDate(false)}
      >
        <DialogTitle
          id='alert-dialog-title'
          className='!bg-[#CDCDCDC7] !mb-4 !text-center !border-b !border-b-[#E6E6E6]'
        >
          <span className='font-semibold text-[21px] text-[#374957]'>
            {t('tasks.filter.date.title')}
          </span>
        </DialogTitle>
        <DialogContent>
          <DateRangeFilter onChange={onChangeDate} />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default memo(DateTypeSelectFilter);
