import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';
import { motion } from 'framer-motion';

import { fade } from 'utils/motion';
import NavBarLinkItem from 'components/navbar-link/NavBarLinkItem';
import BaseTooltip from '../ui/tooltips/BaseTooltip';
import useAuth from 'hooks/useAuth';
import { useAppInfo } from 'context';
import BOSS_MENU_PERMISSIONS_LIST from 'shared/bossMenuPermissionsList';
import useMenu from 'hooks/useMenu';

const BossSidebarPanel = ({ sideBarToggle, setSideBarToggle }) => {
  const [{ info }] = useAppInfo();
  const [{ user, logout }] = useAuth();
  const { t } = useTranslation();
  const { hasChild, permissions } = useMenu();

  const view = (permission) => hasChild('STATISTICS', permission);

  const memoizedMenus = useMemo(() => {
    let menus = [];

    for (let index = 0; index < BOSS_MENU_PERMISSIONS_LIST.length; index++) {
      if (view(BOSS_MENU_PERMISSIONS_LIST[index].code)) {
        menus.push({
          ...BOSS_MENU_PERMISSIONS_LIST[index],
          ind: menus.length,
        });
      }
    }

    return menus.sort((a, b) => a.order - b.order);
  }, [permissions]);

  return (
    <div className='sidebar-panel-wrapper my-shadow-2 overflow-y-auto h-full'>
      <div className='sidebar-panel-header h-[100px] flex items-center justify-center xs:flex-row mt-3 p-3'>
        <Link to='/boss/dashboard' className='no-underline'>
          <motion.img
            variants={fade({
              direction: 'left',
              positionHiddenX: '-30px',
              duration: 0.5,
            })}
            initial='hidden'
            animate='show'
            src={info?.image}
            alt='logo.png'
            className='w-[100px] xs:m-0 mx-auto'
          />
          <div className='boss-title'>BOSS</div>
        </Link>
        <div className='md:hidden close-btn-wrapper'>
          <IconButton
            variant='onlyIcon'
            color='primary'
            onClick={() => setSideBarToggle(false)}
          >
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </div>

      <ul className='sidebar-links-wrapper mt-2 p-3'>
        {memoizedMenus?.map((menu, i) => (
          <NavBarLinkItem
            key={menu?.code}
            linkData={{
              path: menu?.linkData?.path,
              title: t(menu?.linkData?.title),
            }}
            iconName={menu?.iconName}
            delay={i * 0.1}
            customTextComponent={
              menu?.linkData?.beta ? (
                <div className='link-title flex items-center'>
                  {t(menu?.linkData?.title)}{' '}
                  <div className='py-1 px-1 ml-1 rounded bg-base-color-light text-[10px] leading-3 italic text-white font-medium'>
                    Beta
                  </div>
                </div>
              ) : null
            }
          />
        ))}
      </ul>

      <ul className='sidebar-links-wrapper p-3'>
        <NavBarLinkItem
          linkData={{
            path: 'admin/dashboard',
            title: 'Asosiy Sahifa',
          }}
          iconName='bi bi-arrow-up-right-square-fill'
          delay={0.4}
        />

        <NavBarLinkItem
          linkData={{
            path: 'profile',
            title: t('sidebar.profile'),
          }}
          iconName='bi bi-person'
          delay={0}
          customTextComponent={
            <div className='flex flex-col items-start leading-4 ml-2 overflow-hidden'>
              <span className='link-title text-[0.85rem] w-full overflow-hidden overflow-ellipsis whitespace-nowrap'>
                {user?.user?.name}
              </span>
              <span className='text-gray-400 text-[12px] leading-3'>
                {user?.user?.login}
              </span>
            </div>
          }
        />

        <Button
          variant='outlined'
          onClick={logout}
          className='w-full flex items-center'
        >
          <i className='bi bi-box-arrow-left text-base mr-2' />
          <div className='text-[0.85rem] leading-3'>
            {t('sidebar.actions.logout')}
          </div>
        </Button>
      </ul>

      <div className='toggle-btn-wrapper'>
        {sideBarToggle ? (
          <BaseTooltip
            enterDelay={2000}
            leaveTouchDelay={0}
            title={
              <div>
                <Trans i18nKey='sidebar.actions.close'>
                  Yopish uchun <code className='toggle-btn-sign'>[</code> ni
                  bosing
                </Trans>
              </div>
            }
            arrow={true}
            placement='right'
          >
            <IconButton
              variant='toggle'
              onClick={() => setSideBarToggle(false)}
              aria-label='sidebar-toggle-button'
              aria-describedby='sidebar-toggle-button'
            >
              <i className='bi bi-caret-left' />
            </IconButton>
          </BaseTooltip>
        ) : (
          <BaseTooltip
            enterDelay={2000}
            leaveTouchDelay={0}
            title={
              <div>
                <Trans i18nKey='sidebar.actions.open'>
                  Ochish uchun <code className='toggle-btn-sign'>]</code> ni
                  bosing
                </Trans>
              </div>
            }
            arrow={true}
            placement='right'
          >
            <IconButton
              variant='toggle'
              onClick={() => setSideBarToggle(true)}
              aria-label='sidebar-toggle-button'
              aria-describedby='sidebar-toggle-button'
            >
              <i className='bi bi-caret-right' />
            </IconButton>
          </BaseTooltip>
        )}
      </div>
    </div>
  );
};

export default BossSidebarPanel;
