import useMenu from 'hooks/useMenu';
import { memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireChildAuth = ({
  parentPermission,
  permissionCode = '',
  children,
}) => {
  const { menus, hasChild } = useMenu();
  const location = useLocation();

  return menus &&
    menus.length > 0 &&
    hasChild(parentPermission, permissionCode) ? (
    children
  ) : location.state && location.state.allowed ? (
    <Navigate to={location.pathname + location.search} replace />
  ) : (
    <Navigate to={location.pathname.split('/', 3).join('/')} replace />
  );
};

export default memo(RequireChildAuth);
