import { Button, CircularProgress } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import BossCrmCallsFilterDrawer from 'components/dashboard/BossCrmCallsFilterDrawer';
import useTopPanel from 'hooks/useTopPanel';
import CallInfoProgress from 'components/bossCrm';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useLocation } from 'react-router-dom';

function Calls() {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [objectsError, setObjectsError] = useState(false);
  const { search } = useLocation();

  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { setComponent } = useTopPanel();
  const axiosPrivate = useAxiosPrivate();

  const params = new URLSearchParams(search);
  params.delete('page');
  params.delete('type');
  const newUrl = `?${params.toString()}`;

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchData,
  } = useQuery({
    queryKey: ['/analytic/crm/callstaffes', newUrl],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/crm/callstaffes${newUrl || '?'}`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      // if (data.length > 0) {
      // 	setBlockId(data[0]?.block.id)
      // } else {
      // 	setBlockId("")
      // }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
      setObjectsError(true);
    },
    retry: false,
  });
  const {
    data: callHistory,
    isFetching: isFetchingCall,
    refetch,
  } = useQuery({
    queryKey: ['/analytic/crm/callsstatus', search],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/crm/callsstatus${search || '?'}`
      );
      return response.data.data;
    },
    onSuscctu: (data) => {
      // if (data.length > 0) {
      // 	setBlockId(data[0]?.block.id)
      // } else {
      // 	setBlockId("")
      // }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
      setObjectsError(true);
    },
    retry: false,
  });

  useEffect(() => {
    setComponent(<div className='component-title'>{t('boss.crm.title')}</div>);
  }, [i18n.language]);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container pt-2 pb-2 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full'>
            <Button
              variant='filterOutlined'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2 !my-2'
              onClick={() =>
                !(objectsError || isFilterLoading) &&
                setExpanded((prev) => !prev)
              }
              disabled={objectsError || isFilterLoading}
            >
              {t('common.button.filter')}
            </Button>
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4'>
            <Button
              variant='action'
              color='info'
              onClick={() => {
                !objectsError && refetchData();
              }}
              disable={`${isLoading || isFetching}`}
              disabled={objectsError}
            >
              <i
                className={`bi bi-arrow-repeat${
                  isLoading || isFetching ? ' animate-spin' : ''
                }`}
              />
            </Button>
          </div>
        </div>
      </div>
      {isLoading && !data ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <div>
          {isLoading || isFetching ? (
            <div className='absolute top-96 left-1/2 circular-progress-box py-5'>
              <CircularProgress size={35} />
            </div>
          ) : null}
          {!objectsError && data ? (
            <div>
              <div className='component-list-body mb-2 component-title'>
                {t('boss.crm.calls.titleStaffCalls')}
              </div>
              <div className='flex gap-5 mb-3 text-sm'>
                <div className='flex gap-2 items-center'>
                  {t('boss.crm.calls.income')}
                  <div className='h-5 px-6 rounded-md bg-violet-700' />
                </div>
                <div className='flex gap-2 items-center'>
                  {t('boss.crm.calls.outcome')}
                  <div
                    className='h-5 px-6 rounded-md'
                    style={{
                      background: `repeating-linear-gradient(45deg, rgba(110, 0, 184, 0.02), rgba(110, 0, 184, 0.02) 8px, #6e00b8 8px, #6e00b8 10px)`,
                    }}
                  />
                </div>
              </div>
              <CallInfoProgress
                data={data || { types: [{}], data: [] }}
                dataCallHistory={callHistory}
                isFetchingCall={isFetchingCall}
              />
            </div>
          ) : (
            <div className='my-6'>
              <span className='no-data-found-wrapper'>
                <i className='bi bi-exclamation-octagon text-xl mr-1 leading-3' />{' '}
                {t('common.global.noDataFound')}
              </span>
            </div>
          )}
        </div>
      )}
      {!objectsError && (
        <BossCrmCallsFilterDrawer
          open={expanded}
          setOpen={setExpanded}
          // filterData={filterData}
          isLoading={isFilterLoading}
        />
      )}
    </div>
  );
}

export default memo(Calls);
